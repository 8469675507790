import moment from "../formatters/dates";
import Immutable from "seamless-immutable";
import { stringify, parse } from "query-string";
import { prop, keys, sortBy, compose, path, split, pick, uniq } from "ramda";

export const getBrowser = () => {
    let sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf("Chrome") > -1) return "Google Chrome";
    if (sUsrAg.indexOf("Safari") > -1) return "Apple Safari";
    if (sUsrAg.indexOf("Opera") > -1) return "Opera";
    if (sUsrAg.indexOf("Firefox") > -1) return "Mozilla Firefox";
    if (sUsrAg.indexOf("MSIE") > -1) return "Microsoft Internet Explorer";
    return "";
};

export const getOS = () => {
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) return "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) return "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) return "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) return "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) return "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) return "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) return "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) return "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) return "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) return "Linux";
    return "";
};

const parseDate = (is) => (value) => is ? (value ? new moment(value).valueOf() : 0) : value;

const getFieldName = (append, current) => (append ? (isNaN(parseInt(current, 10)) ? `${append}.${current}` : `${append}[${current}]`) : current);

const parseFormArray = (arr) =>
    keys(arr).reduce((memo, key) => {
        if (key.indexOf("[") === -1) {
            memo[key] = arr[key];
            return memo;
        }

        const name = key.replace(/\[\d\]/gi, "");
        memo[name] = [...(memo[name] || []), arr[key]];

        return memo;
    }, {});

export const maped = (arr, index, transform) =>
    arr.reduce(
        (memo, current) => ({
            ...memo,
            [typeof index === "string" ? current[index] : index(current)]: transform ? transform(current) : current,
        }),
        {}
    );

export const upFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const get = (prop, obj) => path(split(".", prop), obj);

export const sortByProp = (data, prop, isDate) =>
    sortBy((item) => {
        const value = compose(path, split)(".", prop)(item);
        return parseDate(isDate)(value);
    })(data);

export const toBoolean = (element) =>
    typeof element === "string" ? (isNaN(parseInt(element, 10)) ? element === "true" : !!parseInt(element, 10)) : !!element;

export const deepPick = (props) => (obj) => {
    const flatt = props.reduce(
        (memo, prop) => {
            if (prop.indexOf(".") === -1) {
                return {
                    ...memo,
                    props: [...memo.props, prop],
                };
            }

            const props = prop.split(".");
            const key = props.shift();

            return {
                props: uniq([...memo.props, key]),
                steps: {
                    ...memo.steps,
                    [key]: [...(memo.steps[key] || []), props.join(".")],
                },
            };
        },
        {
            props: [],
            steps: {},
        }
    );

    const result = pick(flatt.props)(obj);

    return keys(flatt.steps).reduce(
        (memo, current) => ({
            ...memo,
            [current]: deepPick(flatt.steps[current])(memo[current]),
        }),
        result
    );
};

export const isDiff = (objA, objB) => JSON.stringify(objA) !== JSON.stringify(objB);

export const deepDiff = (current, next, props) => (props ? isDiff(deepPick(props)(current), deepPick(props)(next)) : isDiff(current, next));

export const parseFormData = (formData) =>
    parseFormArray(
        keys(formData).reduce(
            (prevData, field) =>
                Immutable.merge(
                    prevData,
                    field.split(".").reduceRight(
                        (memo, current, index, array) => ({
                            [current]: index === array.length - 1 ? formData[field] : { ...memo },
                        }),
                        {}
                    ),
                    { deep: true }
                ),
            {}
        )
    );

export const parseDataToForm = (data, append) =>
    keys(data).reduce(
        (memo, current) => ({
            ...memo,
            ...(typeof data[current] === "object"
                ? parseDataToForm(data[current], getFieldName(append, current))
                : { [getFieldName(append, current)]: data[current] }),
        }),
        {}
    );

export const parseDateToData = (date) => moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");

export const parseDateToForm = (date) => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
export const dateToMY = (date) => moment(date, "YYYY-MM-DD").format("MM/YY");
export const dateToDdd = (date) => moment(date, "YYYY-MM-DD").format("ddd");
export const paseDateWithHour = (date) => moment(date).format("DD-MM-YYYY HH:mm:ss");

export const arrayValueToString = (array, field) =>
    !array
        ? ""
        : array.reduce((memo, current, index) => {
              if (array.length === 1 || index === 0) {
                  return current[field];
              }

              if (index === array.length - 1) {
                  return `${memo}, ${current[field]}`;
              }

              return `${memo}, ${current[field]}`;
          }, "");

export const transformToBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject();
        };

        reader.onload = () => {
            resolve(reader.result);
        };
        if (file && file.type.match("image.*")) {
            reader.readAsDataURL(file);
        }
    });
};

export const base64ToFile = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 1024;

    const byteCharacters = atob(b64Data.replace(`data:image/${contentType};base64,`, ""));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const cloudinaryUrl =
    process.env.POI_APP_ENV === "production"
        ? `https://res.cloudinary.com/${process.env.POI_APP_CLOUDINARY_PROD_BASE}`
        : `https://res.cloudinary.com/${process.env.POI_APP_CLOUDINARY_DEV_BASE}`;

export const paramsToUrl = ({ params, justParams }) => {
    const validationParams = Object.entries(params).reduce((memo, curr) => {
        if (!!curr[1]) {
            memo[curr[0]] = curr[1];
        }
        return memo;
    }, {});

    return justParams ? stringify(validationParams, { arrayFormat: "bracket" }) : `?${stringify(validationParams, { arrayFormat: "bracket" })}`;
};

export const urlToParams = (params) => (params ? parse(params, { arrayFormat: "bracket" }) : {});

const findIndex = (prev, current, index) => {
    if (!current.vehicle || (current.vehicle && !current.vehicle.id)) {
        return index;
    }

    const found = Object.keys(prev).reduce(
        (pre, cur) => (!!prev[cur].find((ele) => ele.vehicle && ele.vehicle.id === current.vehicle.id) ? cur : pre),
        false
    );

    if (found !== false) {
        return found;
    }

    return index;
};

export const mergeTrackers = (entities) =>
    entities && !!entities.length
        ? entities
              //.sort((a, b) => (!!a.vehicle ? -1 : !!b.vehicle ? 0 : 1))
              .reduce((prev, current, index) => {
                  const idx = findIndex(prev, current, index);
                  const prevItem = prop(idx, prev);
                  const item = prevItem ? (Array.isArray(prevItem || {}) ? [...prevItem] : [prevItem]) : [];
                  return {
                      ...prev,
                      [idx]: [...item, current],
                  };
              }, {})
        : [];

export const getRandomColor = () => {
    const letters = "3456789";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 7)];
    }

    return color;
};

export const getBatteryPercentage = ({ min_backup_battery, max_backup_battery, voltage }) => {
    const value = (voltage - min_backup_battery) / (max_backup_battery - min_backup_battery);

    return parseFloat((value * 100).toFixed(2));
};

export const promiseKeys = async (functions) => {
    const arr = Object.values(functions);
    const results = await Promise.all(arr);

    return Object.keys(functions).reduce((memo, curr, index) => {
        memo[curr] = results[index];
        return memo;
    }, {});
};

export const parseTime = (value, unit = "seconds", format = "HH:mm:ss") => moment().startOf("day").add(unit, value).format(format);

export const timeToNumber = (value, unit = "seconds", format = "HH:mm:ss") =>
    value ? moment(value, format).diff(moment().startOf("day"), unit) : value;

export const getValueToSearch = (value) =>
    value
        ? value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
        : "";

export const daysOfWeek = (translate) => [
    {
        label: translate("Domingo"),
        value: "sunday",
    },
    {
        label: translate("Segunda"),
        value: "monday",
    },
    {
        label: translate("Terça"),
        value: "tuesday",
    },
    {
        label: translate("Quarta"),
        value: "wednesday",
    },
    {
        label: translate("Quinta"),
        value: "thursday",
    },
    {
        label: translate("Sexta"),
        value: "friday",
    },
    {
        label: translate("Sábado"),
        value: "saturday",
    },
];

export const brazilianStates = [
    {
        value: "AC",
        label: "Acre",
    },
    {
        value: "AL",
        label: "Alagoas",
    },
    {
        value: "AP",
        label: "Amapá",
    },
    {
        value: "AM",
        label: "Amazonas",
    },
    {
        value: "BA",
        label: "Bahia",
    },
    {
        value: "CE",
        label: "Ceará",
    },
    {
        value: "DF",
        label: "Distrito Federal",
    },
    {
        value: "ES",
        label: "Espírito Santo",
    },
    {
        value: "GO",
        label: "Goiás",
    },
    {
        value: "MA",
        label: "Maranhão",
    },
    {
        value: "MT",
        label: "Mato Grosso",
    },
    {
        value: "MS",
        label: "Mato Grosso do Sul",
    },
    {
        value: "MG",
        label: "Minas Gerais",
    },
    {
        value: "PA",
        label: "Pará",
    },
    {
        value: "PB",
        label: "Paraíba",
    },
    {
        value: "PR",
        label: "Paraná",
    },
    {
        value: "PE",
        label: "Pernambuco",
    },
    {
        value: "PI",
        label: "Piauí",
    },
    {
        value: "RJ",
        label: "Rio de Janeiro",
    },
    {
        value: "RN",
        label: "Rio Grande do Norte",
    },
    {
        value: "RS",
        label: "Rio Grande do Sul",
    },
    {
        value: "RO",
        label: "Rondônia",
    },
    {
        value: "RR",
        label: "Roraima",
    },
    {
        value: "SC",
        label: "Santa Catarina",
    },
    {
        value: "SP",
        label: "São Paulo",
    },
    {
        value: "SE",
        label: "Sergipe",
    },
    {
        value: "TO",
        label: "Tocantins",
    },
];

export const brazilianStatesMatch = brazilianStates
    .map((item) => `(ˆ?.* ${item.value},)|(ˆ?.* ${item.label},)`)
    .reduce((prev, curr) => (prev ? `${prev}|${curr}` : curr));

export const removeCityPrefix = (cityName) => {
    const arrPrefix = [
        "Região Geográfica Intermediária de ",
        "Região Geográfica Intermediária do ",
        "Região Integrada de Desenvolvimento do ",
        "Região Integrada de Desenvolvimento de ",
    ];

    let ret = cityName;

    arrPrefix.map((prefix) => {
        if (cityName.indexOf(prefix) === 0) {
            ret = cityName.substr(prefix.length);
        }
        return prefix;
    });

    return ret;
};

export const extractCityByAddress = (address) => {
    const matched = address.match(brazilianStatesMatch);

    if (matched && !!matched.length) {
        const result = matched[0].split(",");
        const cityUf = !result[result.length - 3]
            ? `${result[result.length - 2]} - ${result[result.length - 1]}`
            : `${result[result.length - 3]} - ${result[result.length - 2]}`;
        return removeCityPrefix(cityUf.trim());
    }

    return "";
};

export const getCountCompanies = (tree, id) => {
    if (!tree || !tree[id]) return 0;

    const result = Object.keys(tree)
        .map((item) => tree[item].childrens.map((children) => children.id))
        .flat(1)
        .filter((companyId) => Object.keys(tree).includes(companyId.toString()));

    return result.map((item) => tree[item].childrens).flat(1).length;
};
