import React, { useState } from 'react'
import Wrapper from '../Wrapper'

import styled from 'styled-components';
import PropTypes from 'prop-types'

const propTypes = {
    onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

const defaultProps = {
    onChange: false,
}

const Ball = styled(Wrapper).attrs(props => ({
    style: {
        transform: `translateX(${props.isActive ? '0.8rem' : '0px'})`
    }
}))`
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    transition: transform 0.3s ease;
`;

const Toggle = ({ onChange }) => {

    const [isActive, setIsActive] = useState(false)

    function onToggle(bool) {
        setIsActive(bool);
        onChange && onChange(bool)
    }

    return (
        <Wrapper flexbox corner='rounded' border padding='2px' width='2rem' onClick={() => onToggle(!isActive)}>
            <Ball bgColor={isActive ? 'primary' : 'white'} isActive={isActive} ></Ball>
        </Wrapper>
    )
}

Toggle.propTypes = propTypes
Toggle.defaultProps = defaultProps

export default Toggle