import React from 'react';
import { Wrapper, Text } from '../../../../../components';

const ProfileBadge = ({ reference }) => {
    return reference ? (
        <Wrapper flexbox margin="8px 0px 0px 0px" position="relative" left="82%" bottom="-1px">
            <Wrapper
                flexbox
                position="absolute"
                left="-1.54%"
                corner="0% 0% 15% 0%"
                height="1.3rem"
                width="0.75rem"
                center
                bgColor=" rgba(242, 217, 155)"
                style={{ transform: 'skew(-28deg)' }}
            />

            <Wrapper flexbox corner="0% 0% 3% 0%" height="1.3rem" width="4rem" center bgColor=" rgba(242, 217, 155)">
                <Text
                    fontSize="tiny"
                    color="black"
                    italic
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '800',
                        fontStyle: 'italic',
                        borderBottom: '1px solid transparent',
                        borderRight: '4px solid transparent',
                    }}>
                    partner
                </Text>
            </Wrapper>
        </Wrapper>
    ): null
};

export default ProfileBadge;
