import colors from '../colors'

const color = colors.border.base

const options = {
  border: {
    _: ({ border }) => `border: ${border};`,

    true: `
      border-width: 1px;
      border-style: solid;
      border-color: ${color};
    `,

    vertical: `
      border-width: 1px 0;
      border-style: solid;
      border-color: ${color};
    `,

    horizontal: `
      border-width: 0 1px;
      border-style: solid;
      border-color: ${color};
    `,

    top: `
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: ${color};
    `,

    bottom: `
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: ${color};
    `,

    left: `
      border-width: 0 0 0 1px;
      border-style: solid;
      border-color: ${color};
    `,

    right: `
      border-width: 0 1px 0 0;
      border-style: solid;
      border-color: ${color};
    `,

    noTop: `
      border-width: 0 1px 1px;
      border-style: solid;
      border-color: ${color};
    `,

    noBottom: `
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: ${color};
    `,

    noLeft: `
      border-width: 1px 1px 1px 0;
      border-style: solid;
      border-color: ${color};
    `,

    noRight: `
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: ${color};
    `,
  },
  underlineHover: ({ underlineHover }) => {
    return `&:hover { border-bottom: ${underlineHover}; }`
  },

  borderStyle: ({ borderStyle }) => `border-style: ${borderStyle};`,
  borderWidth: ({ borderWidth }) => `border-width: ${borderWidth};`,
  borderColor: ({ borderColor }) =>
    `border-color: ${colors.getColor(borderColor)};`,
}

export default options
