import { get } from '../services/fp'
import colors from './colors.json'

const choose = (color, inverted) =>
  color.indexOf('.') === -1
    ? inverted
      ? `${color}.contrast`
      : `${color}.base`
    : color

const getColor = (color, inverted) => {
  const result = get(choose(color, inverted), colors.defaults)

  return typeof result === 'string'
    ? result
    : colors.basic[color]
      ? colors.basic[color]
      : color
}

const rangeColors = [
  '#FF6633', '#809900', '#6680B3', '#FF33FF', '#FFFF99',
  '#B34D4D', '#E6B333', '#3366E6', '#999966', '#99FF99',
  '#80B300', '#E6B3B3', '#FFB399', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
]

export default {
  ...colors.basic,
  ...colors.defaults,
  getColor,
  rangeColors
}