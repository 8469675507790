import React from 'react'
import PropTypes from 'prop-types'

import Wrapper from '../../Wrapper'
import Icon from '../../Icon'


const propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const WithLabel = ({
  children,
  name,
  size,
  fill,
  bearing,
  rotateY,
  ...props
}) => (
  <Wrapper {...props} flexbox>
    {name && (
      <Icon
        name={name}
        size={size}
        fill={fill}
        bearing={bearing}
        rotateY={rotateY}
      />
    )}
    <Wrapper>{children}</Wrapper>
  </Wrapper>
)

WithLabel.propTypes = propTypes

export default WithLabel
