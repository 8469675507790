import React from 'react'
import PropTypes from 'prop-types'
import { pick } from 'ramda'

import Spinner from '../Spinner'
import withStyles, { optionsPropTypes } from './styles'

const propTypes = {
  ...optionsPropTypes,

  padding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

  fontSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  border: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  borderRadius: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  
  truncate: PropTypes.bool,
}

const defaultProps = {
  scroll: false,
  padding: false,
  margin: false,
  strong: false,
  full: false,
  flexbox: false,
  flex: false,
  column: false,
  align: 'initial',
  justify: 'initial',
  isLoading: false,
  center: false,
  border: false,
  font: false,
  borderRadius: false,
  opacityHover: false,
}

const htmlProps = props =>
  pick(
    [
      'id',
      'onClick',
      'className',
      'style',
      'onMouseDown',
      'onMouseEnter',
      'onMouseLeave',
      'onFocus',
      'onBlur',
      'htmlFor',
    ],
    props
  )

const Text = ({ children, isLoading, ...props }) => (
  <p {...htmlProps(props)}>{isLoading ? <Spinner size="huge" /> : children}</p>
)

Text.propTypes = propTypes
Text.defaultProps = defaultProps

export default withStyles(Text)
