import React from 'react';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import 'rc-tooltip/assets/bootstrap.css';
import Text from '../Text';
import styled from 'styled-components';
import Wrapper from '../Wrapper';

const propTypes = {
  placement: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
  ]),

  trigger: PropTypes.oneOf(['hover', 'click', 'focus']),

  overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const defaultProps = {
  placement: 'top',
  trigger: 'hover',
};

const OverlayWrapper = styled(Wrapper)`
  border: 1px solid #414141;
  width: fit-content;
  gap: 0.375rem;
  padding: ${({ type }) => (type === 'simple' ? '0.25rem 0.5rem' : '0.75rem')};
`;

const renderOverlayContent = (title, description, type) => (
  <OverlayWrapper
    flexbox
    column
    bgColor={type === 'simple' ? 'background.03' : 'background.08'}
    fontSize="tiny"
    corner="default"
    type={type}
  >
    {title && <Text strong>{title}</Text>}
    {description && <Text>{description}</Text>}
  </OverlayWrapper>
);

const TooltipWrapper = ({
  title,
  description,
  children,
  type,
  isArrowType = false,
  overlay,
  placement,
  ...props
}) => {
  const shouldRenderOverlay = title || description || overlay;

  const tooltipProps = {
    transitionName: 'rc-tooltip-zoom',
    overlay: overlay || renderOverlayContent(title, description, type),
    placement,
    align: {
      offset:
      type === 'simple' ? (type === 'simple' && isArrowType &&
        (['top', 'topLeft', 'topRight'].includes(placement)) ? [0, -4] : [0, 4]) : [0, -4],
    },
    showArrow: isArrowType,
    overlayClassName: `custom-tooltip-cleaner ${
      type === 'simple' && 'simple-arrow'
    } offset-${placement}`,
    ...props,
  };
  
  return shouldRenderOverlay ? (
    <Tooltip {...tooltipProps}>{children}</Tooltip>
  ) : (
    children
  )
};

TooltipWrapper.propTypes = propTypes;
TooltipWrapper.defaultProps = defaultProps;

export default TooltipWrapper;
