import React from 'react';
import Wrapper from '../../Wrapper';
import Text from '../../Text';
import { simpleCurrency } from '../../../assets/services/formatters';

const InfoLabels = ({ data, sortedData, columns, matchesFilters, matchesSearch, hasMoreLabels }) => {

    function getDistinctLabelValues(columnKey) {
        const distincLabels = data.reduce((prev, curr) => {
            prev[curr[columnKey]] = prev[curr[columnKey]] || [];
            prev[curr[columnKey]].push(curr);

            return prev;
        }, {})

        return Object.entries(distincLabels)
    }

    function getExternalLabelValue(labelType, columnKey) {
        const dataToUse = sortedData.filter(item => matchesFilters(item) && matchesSearch(item));

        const dataLength = dataToUse.length;
        const distinctColumnData = [...new Set(dataToUse.map(item => item[columnKey]))]
        const distinctLength = distinctColumnData.length
        const reducedColumnData = dataToUse.reduce((prev, curr) => prev + curr[columnKey], 0);

        return labelType === 'count' ? dataLength : labelType === 'distinct' ? distinctLength : labelType === 'sum' ?
            simpleCurrency(reducedColumnData) : labelType === 'agg' ? reducedColumnData : simpleCurrency(reducedColumnData / dataLength);
    }

    return (
        <Wrapper flexbox padding='0.5rem 0' gap>

            {
                columns.filter(col => col.hasOwnProperty('aggregateLabels')).map((column, index) => {

                    return (
                        <Wrapper flexbox gap key={index}>

                            {
                                column.aggregateLabels.map((aggLabel, idxLabel) => {
                                    return (
                                        ['sum', 'count', 'average', 'distinct', 'agg'].includes(aggLabel.type) ? (
                                            <Wrapper key={idxLabel} flexbox padding='0.5rem 1rem' corner='default' bgColor='background.03' center fontSize='small' gap='0.5rem' border>

                                                <Text>{aggLabel.display}:</Text>
                                                <Text strong>{getExternalLabelValue(aggLabel.type, column.key)}</Text>

                                            </Wrapper>
                                        ) :
                                            (
                                                <Wrapper flexbox gap>
                                                    {
                                                        getDistinctLabelValues(column.key).map(([key, value]) => (

                                                            <Wrapper key={idxLabel} flexbox padding='0.5rem 1rem' corner='default' bgColor='background.03' center fontSize='small' gap='0.5rem' border>

                                                                <Text>{key}:</Text>
                                                                <Text strong>{value.length}</Text>

                                                            </Wrapper>
                                                        ))
                                                    }
                                                </Wrapper>
                                            )

                                    )
                                })
                            }

                        </Wrapper>
                    )
                })
            }

            {
                hasMoreLabels && (
                    <>{typeof hasMoreLabels === 'function' ? hasMoreLabels() : hasMoreLabels}</>
                )
            }

        </Wrapper>
    )
}

export default InfoLabels