function percentage(value) {
    if (value === undefined || value === null || isNaN(parseFloat(value))) {
        return 'N/A';
    }
    return parseFloat(value).toLocaleString('pt-BR', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

function percentageWithoutDecimal(value) {
    if (isNaN(parseFloat(value))) { return 'N/A'; }
    value = Math.floor(value * 100) / 100;
    return parseFloat(value).toLocaleString('pt-BR', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

export {
    percentage,
    percentageWithoutDecimal
}