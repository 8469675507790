import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Wrapper, Text, Icon } from "../../../components";
import PropTypes from 'prop-types'

const Portal = ({ children }) => {
    return ReactDOM.createPortal(
        children,
        document.body
    );
};

const defaultProps = {
    selected: false,
}

const Option = ({ option, width, onClickOption, level = 0, ...props }) => {
    const haveNestedOptions = option?.options?.length

    const [isOpenNestedOptions, setIsOpenNestedOptions] = useState(false)
    const [dropdownPosition, setDropdownPosition] = useState({});

    const optionRef = useRef(null);

    const handleHoverOption = (bool) => {
        if (bool && optionRef.current) {
            const rect = optionRef.current.getBoundingClientRect();
            setDropdownPosition({
                width: rect.width,
                top: rect.top + window.scrollY,
                left: rect.left + rect.width,
            });
        }

        setIsOpenNestedOptions(bool);
    }

    const renderNestedOptions = (nestedOptions, nextLevel) => {
        return nestedOptions?.map((opt, idx) => (
            <Option key={idx} option={opt} width={width} level={nextLevel} onClickOption={onClickOption} />
        ));
    };

    return (
        <Wrapper ref={optionRef} {...props} width={width} position='relative' flexbox padding='horizontal' opacity={option.disabled && '0.5'} border='bottom'
            bgColor='background.05' bgHover='background.08' onClick={() => (!haveNestedOptions && !option.disabled) && onClickOption(option)} minHeight='2.5rem'
            onMouseEnter={() => haveNestedOptions && handleHoverOption(true)} onMouseLeave={() => haveNestedOptions && handleHoverOption(false)}>

            <Wrapper flexbox justify='space-between' width='100%' align='center'>
                <Text id={option?.key} truncate>{option.name}</Text>

                {
                    haveNestedOptions && (

                        <Wrapper flexbox>
                            <Icon name='chevron_right' size={25} fill='white' />
                        </Wrapper>
                    )
                }

            </Wrapper>

            {
                isOpenNestedOptions && (
                    <Portal>
                        <Wrapper style={{ position: 'absolute', top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px`, overflowY: 'scroll' }} 
                            flexbox column maxHeight="15rem" zIndex={1000000} fontSize='small'>
                            {renderNestedOptions(option.options, level + 1)}
                        </Wrapper>
                    </Portal>
                )
            }

        </Wrapper>
    )
}

// Option.propTypes = propTypes
Option.defaultProps = defaultProps

export default Option