import React, { useRef, useState, useMemo } from 'react';
import { Wrapper, Text } from '../../../../../../../components';
import { useOutsideClick } from '../../../../../../../Hooks';
import PillWrapper from './PillWrapper';

const onboardingInterestValues = [
    { display: 'Com interesse', maskValue: 'Sim', isInterestProduct: true, color: 'success.10' },
    { display: 'Sem interesse', maskValue: 'Não', isInterestProduct: false, color: 'danger.20' },
];

const onboardingIntroducedValues = [
    { display: 'Apresentado', maskValue: 'Sim', isIntroducedProduct: true, color: 'success.10' },
    { display: 'Não apresentado', maskValue: 'Sim', isIntroducedProduct: false, color: 'danger.20' },
];

const OnboardingDropdown = ({
    isAbleToUpdateInterestValues,
    isAbleToUpdateIntroducedValues,
    interestValue,
    interestTagColor,
    interestTagText,
    introducedValue,
    introducedTagColor,
    introducedTagText,
    updateCustomerOnboardingValue,
}) => {
    const dropdownRef = useRef(null);

    const defaultDropdownControllerValue = useMemo(() => {
        return { interest: false, introduced: false };
    }, []);

    const [dropdownController, setDropdownController] = useState(defaultDropdownControllerValue);

    const handleDropdownController = (key) => {
        setDropdownController((prevState) => {
            return {
                ...defaultDropdownControllerValue,
                [key]: !prevState[key],
            };
        });
    };

    const handleTagUpdate = (tagKey, value) => {
        setDropdownController(defaultDropdownControllerValue);

        const currentValue = tagKey === 'interest' ? interestValue : introducedValue;

        if (value !== currentValue) {
            const newValue = { interest: interestValue, introduced: introducedValue, [tagKey]: value };

            const customerInterest = newValue?.interest ?? '-';
            const customerIntroduced = newValue.introduced;

            const interestText = customerInterest === '-' ? 'Não Preenchido' : customerInterest ? 'Com interesse' : 'Sem interesse';
            const introducedText = customerIntroduced ? 'Apresentado' : 'Não apresentado';

            const maskTextValue = `${interestText} e ${introducedText}`;

            updateCustomerOnboardingValue(newValue, maskTextValue);
        }
    };

    return (
        <Wrapper ref={dropdownRef} flexbox height='22px' width='98.8px' gap='0.1rem'>
            <PillWrapper
                isSelected={dropdownController.interest}
                isAbleToUpdate={isAbleToUpdateInterestValues}
                handleDropdownController={handleDropdownController}
                tagColor={interestTagColor}
                tagText={interestTagText}
                corner='15px 0 0 15px'
                tagKey={'interest'}
                onClose={() => setDropdownController(defaultDropdownControllerValue)}
            >
                {onboardingInterestValues.map((item, index) => {
                    return (
                        <Wrapper
                            key={index}
                            flexbox
                            align='center'
                            bgHover='background.09'
                            padding='0.5rem'
                            onClick={() => {
                                handleTagUpdate('interest', item.isInterestProduct);
                            }}
                        >
                            <Text flexbox fontSize='tiny' height='fit-content' color={item.color}>
                                {item.display}
                            </Text>
                        </Wrapper>
                    );
                })}
            </PillWrapper>

            <PillWrapper
                isSelected={dropdownController.introduced}
                isAbleToUpdate={isAbleToUpdateIntroducedValues}
                handleDropdownController={handleDropdownController}
                tagColor={introducedTagColor}
                tagText={introducedTagText}
                corner='0 15px 15px 0'
                tagKey={'introduced'}
                onClose={() => setDropdownController(defaultDropdownControllerValue)}
            >
                {onboardingIntroducedValues.map((item, index) => {
                    return (
                        <Wrapper
                            key={index}
                            flexbox
                            align='center'
                            bgHover='background.09'
                            padding='0.5rem'
                            onClick={() => {
                                handleTagUpdate('introduced', item.isIntroducedProduct);
                            }}
                        >
                            <Text flexbox fontSize='tiny' height='fit-content' color={item.color}>
                                {item.display}
                            </Text>
                        </Wrapper>
                    );
                })}
            </PillWrapper>
        </Wrapper>
    );
};

export default React.memo(OnboardingDropdown);
