import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Text, Wrapper } from '../../../components';

const RestrictedAccess = () => {

    const navigate = useNavigate();

    return (

        <Wrapper flexbox center column padding='6rem 0' width='100%'>

            <Wrapper flexbox center column gap='1rem' width='30rem' textAlign='center' height='20rem'>

                <Icon size={140} opacity='0.4' fill="white" name="lock_person" />
                <Text strong fontSize='huge'>Acesso Restrito</Text>
                <Text fontSize='small' opacity='0.7'>Você não tem permissão para acessar esta página, ou o link pode não estar mais disponível.
                    Acesse outra página ou faça login com um usuário diferente.</Text>

            </Wrapper>

            <Wrapper padding='1rem 0'>

                <Button color='#4891FF' onClick={() => navigate('/home')}>
                    <Text>Ir para a Home</Text>
                </Button>

            </Wrapper>

        </Wrapper>

    )
};

export default RestrictedAccess;
