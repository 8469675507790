let listeners = [];

function handleClickOutside(event) {
    listeners.forEach(({ refs, callback }) => {
        const isOutside = refs.every(ref => ref.current && !ref.current.contains(event.target));
        if (isOutside) {
            callback();
        }
    });
}

export function addListener(refs, callback) {
    if (listeners.length === 0) {
        document.addEventListener('mousedown', handleClickOutside);
    }

    listeners.push({ refs, callback });
}

export function removeListener(refs, callback) {
    listeners = listeners.filter(listener => listener.refs !== refs || listener.callback !== callback);

    if (listeners.length === 0) {
        document.removeEventListener('mousedown', handleClickOutside);
    }
}