import React from 'react';
import { Wrapper, Text, Tooltip, Icon } from '../../../../../components';
import styled from 'styled-components';
import { percentage, simpleCurrency } from '../../../../../assets/services/formatters';
import moment from 'moment';

const getBackground = (isAchieved, percCap) => {
    if (isAchieved) {
        return 'linear-gradient(to top, rgba(9, 192, 119, 0.1), rgba(60, 179, 113, 0))';
    }
    if (percCap >= 1) {
        return 'linear-gradient(to top, rgba(9, 192, 119, 0.1), rgba(60, 179, 113, 0))';
    }
    if (percCap >= 0.8) {
        return 'linear-gradient(to top, rgba(249, 225, 18, 0.1), rgba(249, 225, 18, 0))';
    }
    return 'linear-gradient(to top, rgba(245, 127, 127, 0.1), rgba(245, 127, 127, 0))';
};

const getBorderBottom = (isAchieved, percCap) => {
    if (isAchieved) {
        return '1px solid rgba(9, 192, 119)';
    }
    if (percCap >= 1) {
        return '1px solid rgba(9, 192, 119)';
    }
    if (percCap >= 0.8) {
        return '1px solid rgba(249, 225, 18)';
    }
    return '1px solid rgba(245, 127, 127)';
};

const getHoverBackground = (isAchieved, percCap) => {
    if (isAchieved) {
        return 'linear-gradient(to top, rgba(9, 192, 119, 0.3), rgba(60, 179, 113, 0))';
    }
    if (percCap >= 1) {
        return 'linear-gradient(to top, rgba(9, 192, 119, 0.3), rgba(60, 179, 113, 0))';
    }
    if (percCap >= 0.8) {
        return 'linear-gradient(to top, rgba(249, 225, 18, 0.3), rgba(249, 225, 18, 0))';
    }
    return 'linear-gradient(to top, rgba(245, 127, 127, 0.3), rgba(245, 127, 127, 0))';
};

const StyledMeta = styled(Wrapper).attrs((props) => ({
    style: {
        background: getBackground(props.isAchieved, props.percCap),
        borderBottom: getBorderBottom(props.isAchieved, props.percCap),
        cursor: 'pointer',
        transition: 'background 0.9s ease',
    },
}))`
    &:hover {
        background: ${(props) => getHoverBackground(props.isAchieved, props.percCap)};
    }
`;

const UserSectorProfile = ({ requestUserInfo, user, accessListIds }) => {
    const coreSectors = [10, 33, 34];
    const isUserInCoreSector = coreSectors.includes(user.sector);

    const formattedDate = (startAssessoria) => {
        const startDate = moment(startAssessoria);
        const currentDate = moment();

        const differenceInYears = currentDate.diff(startDate, 'years');
        const differenceInMonths = currentDate.diff(startDate, 'months');

        const monthsWithoutYears = differenceInMonths - differenceInYears * 12;

        if (differenceInYears === 0) {
            return `${monthsWithoutYears} mes${monthsWithoutYears !== 1 ? 'es' : ''}`;
        } else {
            if (monthsWithoutYears === 0) {
                return `${differenceInYears} ano${differenceInYears !== 1 ? 's' : ''}`;
            } else {
                return `${differenceInYears} ano${differenceInYears !== 1 ? 's' : ''} e ${monthsWithoutYears} mes${
                    monthsWithoutYears !== 1 ? 'es' : ''
                }`;
            }
        }
    };

    const profileSections = [
        {
            title: 'NA ASSESSORIA HÁ',
            data: user?.startAssessoria ? formattedDate(user.startAssessoria) : '---',
            isDisplayed: isUserInCoreSector,
        },
        {
            title: 'NA ÁVEL HÁ',
            data: formattedDate(user.hiringDate),
            isDisplayed: true,
        },
    ];

    const consultancySections = [
        {
            title: 'CUSTÓDIA',
            data: simpleCurrency(user?.totalNet),
            isDisplayed: user.sector === 10 || user.sector === 34,
        },
        {
            title: 'GOPLIANCE',
            data: <Text as={'i'} color='danger'>{`${user.goplianceScore} pts`}</Text>,
            isDisplayed: accessListIds.includes(user.id) || requestUserInfo.is_partner,
        },
    ];

    return (
        <Wrapper flexbox column gap='big' padding='0.75rem 0.5rem' margin='5rem 1rem 1rem 1rem' corner='bowed'>
            {isUserInCoreSector && (
                <Wrapper flexbox gap='2rem' style={{ borderBottom: '1px solid #454444' }} padding='0 0 0.5rem 0'>
                    {consultancySections.map((section, index) => {
                        return (
                            section.isDisplayed && (
                                <Wrapper key={index} flexbox gap='small' column>
                                    <Text fontSize='nano' opacity='0.7'>
                                        {section.title}
                                    </Text>
                                    <Text fontSize='tiny'>{section.data}</Text>
                                </Wrapper>
                            )
                        );
                    })}
                </Wrapper>
            )}
            <Wrapper flexbox gap='2rem' style={{ borderBottom: '1px solid #454444' }} padding='0 0 0.5rem 0'>
                {profileSections.map((section, index) => {
                    return (
                        section.isDisplayed && (
                            <Wrapper key={index} flexbox gap='small' column>
                                <Text fontSize='nano' opacity='0.7'>
                                    {section.title}
                                </Text>
                                <Text fontSize='tiny'>{section.data}</Text>
                            </Wrapper>
                        )
                    );
                })}
            </Wrapper>

            {user.sector === 10 && (
                <Wrapper flexbox column gap>
                    <Text fontSize='nano' opacity='0.7'>
                        HISTÓRICO METAS
                    </Text>

                    <Wrapper flexbox gap='small'>
                        {user.historyGoals.map((info, index) => {
                            return (
                                <Tooltip
                                    transitionName=''
                                    key={index}
                                    zIndex={99999}
                                    overlayStyle={{ opacity: '1' }}
                                    width='30%'
                                    overlay={
                                        <Wrapper bgColor='background.08' padding='8px 10px' corner='default' flexbox gap width='18rem' column>
                                            <Wrapper flexbox column gap='big' style={{ borderBottom: '2px solid #454444' }} padding='0 0 0.5rem 0'>
                                                <Text>CAPTAÇÃO:</Text>

                                                <Wrapper flexbox column>
                                                    <Text strong fontSize='large' color={info.percCap >= 1 ? 'success' : 'danger'}>
                                                        {percentage(info.percCap)}
                                                    </Text>

                                                    <Wrapper flexbox>
                                                        <Text>{simpleCurrency(info.doneCap)}</Text>
                                                        <Text opacity='0.7'>/ {simpleCurrency(info.metaCap)}</Text>
                                                    </Wrapper>
                                                </Wrapper>
                                            </Wrapper>

                                            <Wrapper flexbox>
                                                <Wrapper flexbox padding='4px' gap width='50%'>
                                                    <Text>P1:</Text>

                                                    <Icon
                                                        size={20}
                                                        fill={info.p1Goal === 0 ? 'danger' : 'success'}
                                                        name={info.p1Goal === 0 ? 'cancel_filled' : 'verified_filled'}
                                                    />
                                                </Wrapper>

                                                <Wrapper flexbox padding='4px' gap width='50%'>
                                                    <Text>P2:</Text>

                                                    <Icon
                                                        size={20}
                                                        fill={info.p2Goal === 0 ? 'danger' : 'success'}
                                                        name={info.p2Goal === 0 ? 'cancel_filled' : 'verified_filled'}
                                                    />
                                                </Wrapper>
                                            </Wrapper>
                                        </Wrapper>
                                    }
                                >
                                    <StyledMeta
                                        flexbox
                                        center
                                        width='10rem'
                                        corner='3px'
                                        padding='6px'
                                        isAchieved={info?.isAchieved}
                                        percCap={info.percCap}
                                    >
                                        <Text
                                            color={
                                                info?.isAchieved
                                                    ? 'success.20'
                                                    : info.percCap >= 0.8
                                                    ? info.percCap >= 1
                                                        ? 'success.20'
                                                        : 'warning'
                                                    : 'danger.20'
                                            }
                                            strong
                                            fontSize='8px'
                                        >
                                            {moment(info.date, 'YYYY-MM').format('MMM')}
                                        </Text>
                                    </StyledMeta>
                                </Tooltip>
                            );
                        })}
                    </Wrapper>
                </Wrapper>
            )}

            {user?.trofeus && (
                <Wrapper flexbox width='100%' height='3rem' gap='3rem'>
                    <Wrapper flexbox gap='6px' width='100%' column>
                        <Text fontSize='nano' opacity='0.7'>
                            TROFÉUS
                        </Text>

                        {/* <Wrapper flexbox padding='12px 0' align='center' justify='Space-between'>
                                <Wrapper flexbox center gap='1rem'></Wrapper>
                                <Wrapper flexbox>+0</Wrapper>
                            </Wrapper> */}
                    </Wrapper>
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default UserSectorProfile;
