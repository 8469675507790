import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import Wrapper from "../Wrapper";
import Text from "../Text";
import Icon from '../Icon'
import Button from "../Button";
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import './styles.scss'
import styled from "styled-components";
import 'moment/locale/pt-br';
import { useOutsideClick } from "../../Hooks";
import {PropTypes} from 'prop-types'
import { getCurrentMonth, getCurrentWeek, getLast30Days, getLastMonth, getLastWeek } from "../../assets/services/formatters/dates";

const CleanButton = styled(Wrapper)`
    transition: opacity 0.2s ease-in-out

    &:hover{
        opacity: 0.8
    }
`

const propTypes = {
    initialValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),

    staticRanges: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.func
    })),

    onApplyDate: PropTypes.func,

    calendarAxisPosition: PropTypes.oneOf(["right", "left"])
}

const defaultStaticRanges = [
    { name: 'Essa Semana', value: () => { return getCurrentWeek() } },
    { name: 'Semana Passada', value: () => { return getLastWeek() } },
    { name: 'Mês Corrente', value: () => { return getCurrentMonth() } },
    { name: 'Últimos 30 Dias', value: () => { return getLast30Days() } },
    { name: 'Mês Passado', value: () => { return getLastMonth() } },
];

const defaultProps = {
    staticRanges: defaultStaticRanges
}

const DateRangePicker = ({ initialValue, staticRanges, onApplyDate, calendarAxisPosition = 'right' }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [triggerRect, setTriggerRect] = useState(null);

    const triggerRef = useRef(null);
    const dateRef = useRef(null);
    useOutsideClick([dateRef, triggerRef], () => setOpen(false));

    useEffect(() => {
        if (open && triggerRef.current) {
            const rect = triggerRef.current.getBoundingClientRect();
            setTriggerRect(rect);
        }
    }, [open]);

    const toggleCalendar = () => {
        setOpen(!open);
        setTimeout(() => {
            const watermark = document.querySelectorAll('.MuiDateRangeCalendar-root')
            if (watermark[0]) {
                watermark[0].children[0].remove()
            }
        }, 1)
    };

    return (
        <>
            <Wrapper flexbox position='relative' width='fit-content' height='fit-content'>
                <Wrapper
                    flexbox
                    align='center'
                    onClick={toggleCalendar}
                    ref={triggerRef}
                    bgColor='background.02'
                    bgHover='background.04'
                    padding='horizontal'
                    height='2rem'
                    fontSize='small'
                    corner='default'
                    gap
                >
                    <Icon name='date_range' fill='white' size={22} />
                    <Text>{value[0] ? moment(value[0]).format('DD/MM/YYYY') : 'DD/MM/AAAA'} - {value[1] ? moment(value[1]).format('DD/MM/YYYY') : 'DD/MM/AAAA'}</Text>
                </Wrapper>
            </Wrapper>

            {open && triggerRect && ReactDOM.createPortal(
                <Wrapper
                    ref={dateRef}
                    flexbox
                    zIndex={999999999999}
                    position='absolute'
                    top={`${triggerRect.bottom + 8}px`}
                    left={calendarAxisPosition === 'right' ? `${triggerRect.left}px` : 'auto'}
                    right={calendarAxisPosition === 'left' ? `${window.innerWidth - triggerRect.right}px` : 'auto'}
                    bgColor='#1E1E1E'
                    height='fit-content'
                    align='center'
                    corner='default'
                    boxShadow='0px 3.35782px 12px 0px rgba(0, 0, 0, 0.40);'
                >
                    {staticRanges?.length > 0 && (
                        <Wrapper flexbox column gap height='100%' width='12rem' padding='0.5rem 1rem'>
                            {staticRanges.map((range, index) => (
                                <Wrapper
                                    key={index}
                                    onClick={() => setValue(range.value)}
                                    bgColor='background.08'
                                    padding='0.5rem 1rem'
                                    corner='rounded'
                                    bgHover='background.10'
                                    width='fit-content'
                                >
                                    <Text fontSize='tiny'>{range.name}</Text>
                                </Wrapper>
                            ))}
                        </Wrapper>
                    )}

                    <Wrapper flexbox column position='relative'>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt-br'>
                            <DateRangeCalendar
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                                calendars={1}
                            />
                        </LocalizationProvider>

                        <Wrapper flexbox position='absolute' bottom='5%' right='5%' gap='1.5rem' center>
                            <CleanButton flexbox onClick={() => { setValue([null, null]) }}>
                                <Text color='#C9C9C9' border='bottom' borderColor='#C9C9C9' fontSize='small'>Limpar</Text>
                            </CleanButton>

                            <Button color='#10985B' onClick={() => { onApplyDate && onApplyDate(value); toggleCalendar() }}>
                                <Text>Aplicar</Text>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>,
                document.body
            )}
        </>
    );
};

DateRangePicker.propTypes = propTypes;
DateRangePicker.defaultProps = defaultProps;

export default DateRangePicker;
