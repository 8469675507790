import React, { useState } from 'react';
import Loading from '../../../Loading';
import { DataTable, Wrapper, Text } from '../../../../../components';
import moment from 'moment';
import PageControl from '../../../PageControl';
import { colors } from '../../../../../assets/theme';

const NpsResponses = ({ customer, viewInfo, isLoading }) => {
    const truncateFormat = (obj, key) => {
        return <Text truncateHover>{obj[key]}</Text>;
    };

    const npsNote = (obj, key) => {
        const color = obj[key] >= 9 ? 'green' : obj[key] >= 7 ? 'yellow' : 'red'

        return (
            <Wrapper flexbox height='1.7rem'>

                <Wrapper flexbox height='100%' gap='0.1rem'>
                    <Wrapper flexbox center bgColor={color} width='0.25rem' style={{ transform: 'skewX(-20deg)' }} />
                    <Wrapper flexbox center bgColor={color} width='0.25rem' style={{ transform: 'skewX(-20deg)' }} />
                </Wrapper>

                <Wrapper flexbox center bgColor={`${colors.getColor(color)}33`} width='2rem' style={{ transform: 'skewX(-20deg)' }}>

                    <Wrapper style={{ transform: 'skewX(20deg)' }}>
                        <Text strong color={color}>{obj[key]}</Text>
                    </Wrapper>

                </Wrapper>

            </Wrapper>
        );
    };

    const npsResponsescolumns = [
        { key: 'date', header: 'Data', format: (obj, key) => moment(obj[key]).format('DD/MM/YYYY') },
        { key: 'advisor', header: 'Assessor' },
        { key: 'surveyType', header: 'Tipo' },
        { key: 'surveyMessage', header: 'Comentários', format: truncateFormat, colSpan: 3 },
        { key: 'surveyNote', header: 'Nota', format: npsNote },
    ];

    const openNpsColumns = [
        { key: 'date', header: 'Data', sortable: true, format: (obj, key) => { return moment(obj[key]).format('DD/MM/YYYY') } },
        { key: 'customerCode', header: 'Cód. Cliente', filter: true },
        { key: 'customerName', header: 'Nome do Cliente', filter: true, format: truncateFormat },
        { key: 'surveyType', header: 'Tipo Pesquisa', filter: true },
    ]

    const npsTables = [
        { key: 'npsResponses', name: 'Últimas responstas NPS', columns: npsResponsescolumns, data: viewInfo?.response || [] },
        { key: 'openNps', name: 'Em aberto', columns: openNpsColumns, data: viewInfo?.openNps || [] }
    ]

    return (
        <Wrapper flexbox>
            {
                !isLoading ? (
                    <Wrapper flexbox column padding='2rem' gap='3rem'>

                        {
                            npsTables.map((npsTable, index) => {
                                return (
                                    <Wrapper key={index} flexbox column gap='big'>

                                        <Text medium>{npsTable.name}</Text>

                                        <Wrapper fontSize='tiny'>
                                            <DataTable
                                                bgColor='background.03'
                                                columns={npsTable.columns}
                                                data={npsTable.data}
                                                rowProps={{ lineHeight: '2.5rem' }}
                                                headerProps={{ lineHeight: '2rem' }}
                                                pagination={10}
                                            />
                                        </Wrapper>

                                    </Wrapper>
                                )
                            })
                        }

                    </Wrapper>
                ) : (
                    <Loading type='table' />
                )
            }
        </Wrapper>
    );
};

export default NpsResponses;
