import React, { useEffect } from "react";
import { Wrapper } from "../../../../../components";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const WalletChart = (props) => {
    const { data, shouldRenderChart } = props;

    useEffect(() => {

        if (!shouldRenderChart) {
            return;
        }

        let root = am5.Root.new("chartdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = root.container.children.push(am5percent.PieChart.new(root, {
            radius: am5.percent(60),
            innerRadius: am5.percent(70),
            layout: root.horizontalLayout,
            x: am5.percent(-10),
        }));

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(am5percent.PieSeries.new(root, {
            name: "Series",
            valueField: "net",
            categoryField: "name",
        }));

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data

        const formattedData = data.flatMap(item => item?.assets).filter(item => item.net > 0).map(item => {
            return {
                ...item,
                sliceSettings: {
                    fill: am5.color(item.color),
                    stroke: am5.color(item.color)
                }
            }
        })
        // .sort((a, b) => { return b.net - a.net })

        series.slices.template.setAll({
            templateField: "sliceSettings"
        });

        series.slices.template.set("tooltipText", "{category}: R$ {value.formatNumber('#,###.00')}");

        series.data.setAll(formattedData)
        
        // Disabling labels and ticks
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        // Adding gradients
        // series.slices.template.set("strokeOpacity", 0);
        // series.slices.template.set("fillGradient", am5.RadialGradient.new(root, {
        //     stops: [{
        //         brighten: -0.8
        //     }, {
        //         brighten: -0.8
        //     }, {
        //         brighten: -0.5
        //     }, {
        //         brighten: 0
        //     }, {
        //         brighten: -0.5
        //     }]
        // }));

        // Create legend
        // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerY: am5.percent(50),
            y: am5.percent(50),
            x: am5.percent(70),
            layout: root.verticalLayout
        }));
        // set value labels align to right
        legend.valueLabels.template.setAll({ textAlign: "right" })
        // set width and max width of labels
        legend.labels.template.setAll({
            maxWidth: 140,
            width: 140,
            oversizedBehavior: "wrap",
            fontSize: 14,
            fill: '#FFF',
            opacity: '0.8'
        });

        legend.valueLabels.template.setAll({
            fontSize: 14,
            fill: '#FFF',
            opacity: '0.8'
        });

        legend.markers.template.setAll({
            width: 14, // Width of the marker
            height: 14  // Height of the marker
        });

        legend.data.setAll(series.dataItems);


        // Play initial series animation
        // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [shouldRenderChart]);

    return <Wrapper width='100%' height='100%' id='chartdiv'></Wrapper>;
};

export default WalletChart;
