import { useEffect } from 'react';
import { addListener, removeListener } from '../../Events/OutsideClickManager';

function useOutsideClick(refs, callback) {
    useEffect(() => {
        const elements = Array.isArray(refs) ? refs : [refs];

        addListener(elements, callback);

        return () => {
            removeListener(elements, callback);
        };
    }, [callback, ...refs.map((ref) => ref.current)]);
}

export default useOutsideClick;
