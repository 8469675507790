import React from 'react'
import { TableSkeleton, BoxSkeleton, InfoHeaderSkeleton, KanbanSkeleton, PieSkeleton, ProgressBarSkeleton } from './Skeletons'

const Loading = ({ type, ...props }) => {

    return (
        type === 'table' ? (
            <TableSkeleton columns={props.columns} />
        ) : type === 'box' ? (
            <BoxSkeleton height={props?.height || '4.5rem'} width={props?.width || '100%'} {...props} />
        ) : type === 'info_header' ? (
            <InfoHeaderSkeleton />
        ) : type === 'kanban' ? (
            <KanbanSkeleton />
        ) : type === 'pie' ? (
            <PieSkeleton height={props?.height || '4.5rem'} width={props?.width || '100%'} {...props} />
        ) : type === 'progress_bar' ? (
            <ProgressBarSkeleton height={props?.height || '1.5rem'} width={props?.width || '100%'} />) : <></>
    )
}

export default Loading