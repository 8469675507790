import dimensions from '../dimensions'

const size = dimensions.padding

const options = {
  padding: {
    _: ({ padding }) => `padding: ${padding};`,
    true: `padding: ${size};`,
    vertical: `padding: ${size} 0;`,
    horizontal: `padding: 0 ${size};`,
    top: `padding-top: ${size};`,
    bottom: `padding-bottom: ${size};`,
    left: `padding-left: ${size};`,
    right: `padding-right: ${size};`,
    noTop: `padding: 0 ${size} ${size};`,
    noBottom: `padding: ${size} ${size} 0;`,
    noLeft: `padding: ${size} ${size} ${size} 0;`,
    noRight: `padding: ${size} 0 ${size} ${size};`,
  },
}

export default options
