import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import RoutesController from './routes';
import { AppProvider } from './Context';
import FetchModalManager from './Events/FetchModalManager';
import HttpResponseDisplayManager from './Events/HttpResponseDisplayManager';

createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <AppProvider>
    <HttpResponseDisplayManager />
    <FetchModalManager />
    <RoutesController />
  </AppProvider>
  // </React.StrictMode>,
);



