import React from "react";
import styled, { keyframes } from "styled-components";
import { Wrapper } from "../../../../../components";

const shimmer = keyframes`
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
`;

// Styled Component for the shimmering div
const ShimmerDiv = styled.div`
    width: ${(props) => props.width && props.width};
    height: ${(props) => props.height && props.height};
    background: linear-gradient(90deg, #252525 25%, #333333 50%, #252525 75%);
    background-size: 200% 100%;
    animation: ${shimmer} 1.5s ease infinite;
`;

const InfoHeaderSkeleton = () => {
  return (
    <Wrapper flexbox height="100%">
      {[0, 1, 2].map((index) => {
        return (
          <Wrapper key={index} flexbox column padding="10px 15px" gap="35px" width="33%" border={index !== 2 && "right"} className="opacity_card">
            <ShimmerDiv height="25px" width="30%" />
            <Wrapper flexbox column gap="20px">
              <ShimmerDiv height="50px" width="50%" />
              <ShimmerDiv height="50px" width="25%" />
            </Wrapper>
          </Wrapper>
        );
      })}
    </Wrapper>
  );
};

export default InfoHeaderSkeleton;
