function simpleCurrency(amount, locale = 'pt-BR', currency = 'BRL', digits = 2, includeDecimals = true) {
  if (amount === undefined || amount === null || isNaN(parseFloat(amount))) {
    return 'N/A';
  }
  const options = {
    style: 'currency',
    currency: currency, // You can modify the currency code here
    minimumFractionDigits: includeDecimals ? digits : 0,
    maximumFractionDigits: digits
  };

  return amount.toLocaleString(locale, options);
}

function shortCurrency(value, withoutLabel = false) {
  if (isNaN(parseFloat(value))) { return 'N/A'; }

  if (value >= 1000 && value < 1000000) {
    return (withoutLabel ? '' : 'R$') + (value / 1000).toFixed(1).replace('.0', '') + 'K';
  } else if (value >= 1000000 && value < 10000000) {
    return (withoutLabel ? '' : 'R$') + (value / 1000000).toFixed(1).replace('.0', '') + 'M';
  }

  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    notation: 'compact',
    maximumFractionDigits: 0,
  }).format(value);
}

function currencyPennyless(value, plusMinus = false) {
  if (isNaN(parseFloat(value))) { return 'N/A'; }
  let plus = plusMinus && value > 0 ? '+' : '';
  return plus + parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}


export {
  simpleCurrency,
  shortCurrency,
  currencyPennyless
}