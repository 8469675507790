import React, { useState, useEffect } from 'react';
import Wrapper from '../Wrapper';
import Text from '../Text';
import Icon from '../Icon';
import PropTypes from 'prop-types'

const propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        src: PropTypes.string.isRequired,
        // extension: propTypes.string
    })),

    displayType: PropTypes.oneOf(['simple', 'preview'])

}

const defaultProps = {
    displayType: 'simple'
}

const extensionColors = {
    '.xlsx': '#0B8F54',
    '.xls': '#0B8F54',
    '.csv': '#0B8F54',
    '.pdf': '#EE4747',
}

const FileDisplay = ({ files, displayType, withLoader, onAddFile, ...props }) => {

    const [filePreviews, setFilePreviews] = useState();
    const [insertedFiles, setInsertedFiles] = useState([]);

    const handleFileChange = async event => {
        const eventFiles = Array.from(event.target.files);

        if (eventFiles.length) {

            eventFiles.forEach(file => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    file.preview = reader.result;
                };
                reader.readAsDataURL(file);

            });

            // setInsertedFiles(prevState => [...prevState, eventFiles])
            onAddFile && await onAddFile(eventFiles)
            // setInsertedFiles()
        }
    };

    // useEffect(() => {
    //     setFilePreviews([...files, ...insertedFiles])
    // }, [files, insertedFiles])

    return (
        <Wrapper flexbox column gap='large' corner='bowed' {...props}>

            {
                withLoader && (
                    <Wrapper flexbox column gap>

                        <Text fontSize='mini'>Adicionar anexos:</Text>

                        {
                            displayType === 'simple' ? (
                                <Wrapper>
                                    <input type='file' id='fileInputComponent' multiple style={{ display: 'none' }} onChange={handleFileChange} />
                                    <label htmlFor='fileInputComponent' id='fileLabelComponent' style={{ cursor: 'pointer' }}>
                                        <Wrapper flexbox column center border={`dashed 2px #555555`} padding='0.8rem' corner='default' bgColor='background.06'>
                                            <Icon name='cloud_upload' fill='text.dark' size={30} />
                                            <Text fontSize='mini' color='#4891FF' truncate style={{ borderBottom: '1px solid #4891FF' }}>Selecione arquivos em seu computador</Text>
                                        </Wrapper>
                                    </label>
                                </Wrapper>
                            ) : (
                                <Wrapper>
                                    <input type='file' id='fileInputComponent' multiple style={{ display: 'none' }} /*onChange={handleFileChange}*/ />
                                    <label htmlFor='fileInputComponent' id='fileLabelComponent' style={{ cursor: 'pointer' }}>
                                        <Wrapper flexbox column center border={`dashed 2px #555555`} padding corner='default' bgColor='background.06'>
                                            <Icon name='upload' fill='white' size={45} />
                                            <Text fontSize='mini' color='#4891FF' style={{ borderBottom: '1px solid #4891FF' }}>Selecione arquivos em seu computador</Text>
                                        </Wrapper>
                                    </label>
                                </Wrapper>
                            )
                        }

                    </Wrapper>
                )
            }

            <Wrapper flexbox column width='100%' gap>

                <Text fontSize='mini'>Arquivos enviados:</Text>

                <Wrapper flexbox column gap>
                    {
                        files?.map((file, index) => {
                            const fileTagColor = extensionColors?.[file.extension] || '#006793'
                            return (
                                <Wrapper key={index} flexbox justify='space-between' align='center' bgColor='background.08' padding='0.2rem'
                                    onClick={() => window.open(file.src, '_blank').focus()} corner='bowed' border gap>

                                    <Wrapper flexbox align='center' gap>
                                        {/* <Icon name='attach_file' size={15} fill='white' /> */}
                                        <Wrapper flexbox center padding='0.1rem 0.3rem' corner='default' bgColor={fileTagColor}>
                                            <Text fontSize='nano' strong>{file.extension.replace('.', '').toUpperCase()}</Text>
                                        </Wrapper>

                                        <Text fontSize='tiny' truncate>{file.name}</Text>
                                    </Wrapper>

                                    <Icon name='open_in_new' size={15} fill='white' />

                                </Wrapper>
                            )
                        })
                    }
                </Wrapper>

                {/* {
                    filePreviews.length > 0 ? (
                        <Wrapper flexbox height='60px' gap>
                            {
                                filePreviews.map((preview, index) => {
                                    return (
                                        <img key={index} src={preview} alt={`File Preview ${index}`} style={{ width: 'auto', maxHeight: '100%' }} />
                                    )
                                })
                            }
                        </Wrapper>
                    ) : (
                        <Wrapper flexbox column center width='100%' gap opacity='0.5'>
                            <Icon name='attach_file' fill='white' size={45} />
                            <Text fontSize='mini'>Nenhum arquivo Selecionado</Text>
                        </Wrapper>
                    )
                } */}

            </Wrapper>

        </Wrapper>
    )
}

FileDisplay.propTypes = propTypes
FileDisplay.defaultProps = defaultProps

export default FileDisplay