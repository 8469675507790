import React, { useEffect, useState, useRef, useContext } from "react";
import { Wrapper, Text } from "../../../components";
import ProgressBar from "../ProgressBar";
import { removeElementsOpacity } from "../../../assets/services/apis/utils";
import styled from "styled-components";
import { AppContext } from "../../../Context";

const LoadingWrapper = styled(Wrapper)`
    position: absolute;

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    z-index: 9999999;
`

const FetchModal = ({ progress, isLoadingBarOk }) => {

    const { globalContext } = useContext(AppContext)
    const loadingPhrases = globalContext?.loadingPhrases

    const [selectedPhrase, setSelectedPhrase] = useState(loadingPhrases?.[Math.floor(Math.random() * loadingPhrases.length)]?.phrase)
    const [isFading, setIsFading] = useState(false);

    const progressIntervalRef = useRef(null)
    const loadingPhrasesIntervalRef = useRef(null)

    const [fakeProgress, setFakeProgress] = useState(progress);

    useEffect(() => {
        progressIntervalRef.current = setInterval(() => {
            setFakeProgress(prevState => prevState + (5 / 100))
        }, 1000);

        loadingPhrasesIntervalRef.current = setInterval(() => {
            setIsFading(true);

            setTimeout(() => {
                setSelectedPhrase(loadingPhrases?.[Math.floor(Math.random() * loadingPhrases.length)].phrase);
                setIsFading(false);
            }, 500);

        }, 10000);

        return () => {
            if (progressIntervalRef.current) clearInterval(progressIntervalRef.current);
            if (loadingPhrasesIntervalRef.current) clearInterval(loadingPhrasesIntervalRef.current);
        };

    }, [])

    useEffect(() => {
        removeElementsOpacity(1000, 'opacity_card_loading_modal')
    }, [])

    useEffect(() => {
        setFakeProgress(progress)
    }, [progress])


    return (
        <LoadingWrapper className='opacity_card_loading_modal' flexbox column center>

            <Wrapper position='absolute' width='100%' height='100%' bgColor='background.00' opacity='0.9' top='0' left='0'></Wrapper>

            <Wrapper flexbox column width='30rem' center zIndex={10} gap='large'>

                <Wrapper flexbox column width='100%' center>

                    <Text>Aguarde...</Text>

                    <Wrapper width='80%' padding>

                        <ProgressBar
                            id='loading_progress'
                            completed={fakeProgress}
                            duration={1}
                            size='0.5rem'
                            isLoadingBarOk={isLoadingBarOk}
                        />

                    </Wrapper>
                </Wrapper>

                <Wrapper flexbox width='100%' transition='opacity 0.5s ease-in-out' opacity={isFading ? '0' : '0.6'}>
                    <Text textAlign='center' fontSize='small'>{selectedPhrase}</Text>
                </Wrapper>

            </Wrapper>

        </LoadingWrapper>
    )
}

export default FetchModal