import React, { useState, useEffect } from 'react'
import { Wrapper, Text } from '../../../components'
import { percentage, simpleCurrency } from '../../../assets/services/formatters'
import PropTypes from 'prop-types'
import { props } from './styles'
import Bullet from './Bullet'

const propTypes = {
    // id: PropTypes.id.isRequired,
    size: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    duration: PropTypes.number,
    completed: PropTypes.number,
    bgColor: PropTypes.string,

    bullets: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            bulletPoints: PropTypes.arrayOf(PropTypes.shape({
                barPoint: PropTypes.number,
                label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
                tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func])
            }))
        })
    ]),

    barSteps: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

}

const defaultProps = {
    size: 'small',
    duration: 2,
    completed: 0,
    bgColor: 'primary',
    bullets: false,
    barSteps: false,
    size: 'default',
    isLoadingBarOk: true
}

const ProgressBar = ({ id, duration, completed, bgColor, bullets, isLoadingBarOk, barSteps, size, ...props }) => {

    const [initBar, setInitBar] = useState(false)

    function increaseBulletsProgressBar() {
        const progress = completed;
        const checkpoints = bullets?.bulletPoints.map(bullet => bullet.barPoint)
        let count = 0;

        setTimeout(() => {
            const progressBars = document.querySelectorAll(`.bar_${id}`);
            const progressPoints = document.querySelectorAll(`.point_${id}`);

            const colors = ['#FFE401', '#1D4BC0', '#1D4BC0', '#0D235C'];

            function recursiveBar(count) {
                if (count < progressBars.length) {
                    const bar = progressBars[count];
                    const point = progressPoints[count];

                    if (progress < checkpoints[count]) {
                        if (count === 0) {
                            bar.style.width = `${(progress / checkpoints[count]) * 100}%`;
                        } else {
                            if (progress <= checkpoints[count - 1]) {
                                bar.style.width = '0%';
                            } else {
                                const previousCheckpoint = checkpoints[count - 1];
                                const progressInCurrentRange = progress - previousCheckpoint;
                                const range = checkpoints[count] - previousCheckpoint;
                                bar.style.width = `${(progressInCurrentRange / range) * 100}%`;
                            }
                        }
                    } else {
                        bar.style.width = '100%';
                        bar.ontransitionend = () => {
                            // point.style.backgroundColor = colors[count];
                            point.style.backgroundColor = bgColor;
                            point.ontransitionend = () => {
                                recursiveBar(count + 1);
                            };
                        };
                    }
                }
            }

            recursiveBar(count);
        }, 500);
    }

    function increaseStepsProgressBar() {
        const progress = completed;
        const step = (100 / barSteps) / 100

        let count = 0;

        setTimeout(() => {
            const progressBars = document.querySelectorAll(`.bar_${id}`);

            function recursiveBar(count) {
                if (count < progressBars.length) {
                    const bar = progressBars[count];

                    if (progress < (step * (count + 1))) {
                        if (count === 0) {
                            bar.style.width = `${(progress / (step * (count + 1))) * 100}%`;
                        } else {
                            if (progress <= (step * count)) {
                                bar.style.width = '0%';
                            } else {
                                const previousCheckpoint = step * (count + 1);
                                const progressInCurrentRange = progress - previousCheckpoint;
                                const range = (step * count) - previousCheckpoint;
                                bar.style.width = `${(progressInCurrentRange / range) * 100}%`;
                            }
                        }
                    } else {
                        bar.style.width = '100%';
                        bar.ontransitionend = () => {
                            recursiveBar(count + 1);
                        };
                    }
                }
            }

            recursiveBar(count);
        }, 500);
    }

    const getBarWidth = (bulletCount) => {
        if (bulletCount === 0) {
            return `${bullets.bulletPoints[bulletCount].barPoint * 100}%`
        } else {
            return `${(bullets.bulletPoints[bulletCount].barPoint - bullets.bulletPoints[bulletCount - 1].barPoint) * 100}%`
        }
    }

    useEffect(() => {
        if (bullets) {
            increaseBulletsProgressBar()
        } else if (barSteps) {
            increaseStepsProgressBar()
        } else {
            setInitBar(true)
        }
    }, [])

    return (
        <>
            {
                bullets ?
                    <Wrapper flexbox width='100%' align='center'>
                        {
                            bullets?.bulletPoints?.map((bullet, index) => {
                                return (
                                    <Wrapper key={index} flexbox align='center' width={bullet.barPoint === 0 ? '3rem' : getBarWidth(index)}>
                                        <Wrapper flexbox rounded height={size} width='100%' corner='rounded' bgColor='background.01' margin='horizontal'>
                                            <Wrapper
                                                className={`bar_${id}`}
                                                corner='rounded'
                                                height='100%'
                                                width={'0%'}
                                                bgColor={bgColor}
                                                style={{
                                                    float: 'left',
                                                    transition: `width ${duration}s ease-in-out`,
                                                    // backgroundImage: `linear-gradient(to right, ${index === 0 ? '#ef4444' : '#ffe600'} 10%, ${index === 0 ? '#ffe600' : '#1dc077'})`
                                                }}
                                            >
                                            </Wrapper>
                                        </Wrapper>
                                        <Bullet
                                            id={id}
                                            bullet={bullet}
                                            bgColor={bgColor}
                                        />
                                    </Wrapper>
                                )
                            })
                        }
                    </Wrapper>
                    : barSteps ? (
                        <Wrapper flexbox gap='0.1rem' width='100%' align='center'>
                            {
                                [...Array(barSteps).keys()].map((step, index) => {
                                    return (
                                        <Wrapper key={index} flexbox align='center' width={`${100 / barSteps}%`}>
                                            <Wrapper flex rounded height={size} width='100%' corner='rounded' bgColor='background.01'>
                                                <Wrapper
                                                    className={`bar_${id}`}
                                                    corner='rounded'
                                                    height='100%'
                                                    width='0%'
                                                    bgColor='primary'
                                                    style={{
                                                        float: 'left',
                                                        transition: `width ${duration}s ease-in-out`,
                                                    }}
                                                >
                                                </Wrapper>
                                            </Wrapper>
                                        </Wrapper>
                                    )
                                })
                            }
                        </Wrapper>
                    ) :
                        <Wrapper flexbox rounded height={size} width='100%' corner='rounded' bgColor='background.01'>
                            <Wrapper
                                corner='rounded'
                                height='100%'
                                width={initBar ? percentage(completed > 1 ? 1 : completed).replace(',', '.') : '0%'}
                                style={{ float: 'left', transition: `width ${duration}s ease-in-out` }}
                                bgColor={isLoadingBarOk ? bgColor : "danger"}>
                            </Wrapper>
                        </Wrapper>
            }
        </>
    )
}

ProgressBar.propTypes = propTypes
ProgressBar.defaultProps = defaultProps

export default ProgressBar