import withStyles, { extractPropTypes } from '../../assets/services/styles';

import { bg, border, attached, padding, flexbox, margin, radius, corner, font, color } from '../../assets/theme/by';

const base = `
  overflow: initial;
  min-width: 0px;
  margin: 0px;
`;

const options = {
  ...bg,
  ...border,
  ...corner,
  ...attached,
  ...padding,
  ...margin,
  ...font,
  ...flexbox,
  ...radius,
  ...color,

  position: ({ position }) => `position: ${position};`,
  height: ({ height }) => `height: ${height};`,
  textAlign: ({ textAlign }) => `text-align: ${textAlign};`,
  textDecoration: ({ textDecoration }) => `text-decoration: ${textDecoration};`,
  weight: ({ weight }) => `font-weight: ${weight};`,

  scroll: {
    true: `
      overflow: auto;
    `,

    hidden: `
      overflow: auto;
    `,

    horizontal: `
      overflow: auto;
      flex-shrink: none;
    `,
  },

  minHeight: ({ minHeight }) => `min-height: ${minHeight};`,
  minWidth: ({ minWidth }) => `min-width: ${minWidth};`,
  maxWidth: ({ maxWidth }) => `max-width: ${maxWidth};`,
  width: ({ width }) => `width: ${width};`,
  opacity: ({ opacity }) => `opacity: ${opacity};`,

  full: {
    true: `height: 100vh;`,
    '%': `height: 100%;`,
  },

  isLoading: `
    background: rgba(0,0,0,0.01);
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  `,

  onClick: `
    cursor: pointer;
  `,

  truncate: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  truncateHover: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: white-space 0.2s ease-in-out;

    &:hover{
      white-space: wrap;
    }
  `,

  underlineHover: `
      &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
      opacity: 0.99
    }
  `,

  container: `
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
  `,

  opacityHover: `
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  `,

  zIndex: ({ zIndex }) => `
    z-index: ${zIndex};
  `,

  content: ({ content }) => `
    align-content: ${content};
  `,
};

export const optionsPropTypes = extractPropTypes(options);
export default withStyles(base, options);
