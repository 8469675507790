import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { DisappearedLoading } from 'react-loading'
import withStyles, { optionsPropTypes } from './styles'

import { colors } from '../../assets/theme'

const propTypes = {
  ...optionsPropTypes,

  padding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  gap: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  corner: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

  fontSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  border: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  borderRadius: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  top: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  bottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  right: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  textAlign: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  left: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  opacityHover: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  draggable: PropTypes.bool,

  cursor: PropTypes.string
}

const defaultProps = {
  scroll: false,
  padding: false,
  gap: false,
  margin: false,
  strong: false,
  full: false,
  flexbox: false,
  flex: false,
  column: false,
  align: 'initial',
  justify: 'initial',
  loadingSize: 'huge',
  isLoading: false,
  center: false,
  border: false,
  font: false,
  borderRadius: false,
  textAlign: 'initial',
  opacityHover: false,
  draggable: false,
}

const availableProps = [
  'data-tag',
  'className',
  'contentEditable',
  'htmlFor',
  'id',
  'onBlur',
  'onClick',
  'onFocus',
  'onMouseDown',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseOver',
  'onMouseOut',
  'onDragStart',
  'onDragEnter',
  'onDragOver',
  'onDragEnd',
  'draggable',
  'onPaste',
  'style',
  'title',
  'cursor',
]

const htmlProps = props =>
  Object.keys(props).reduce(
    (prev, cur) =>
      availableProps.includes(cur)
        ? {
          ...prev,
          [cur]: props[cur],
        }
        : prev,
    {}
  )


// const myRef = useRef(null);

// useEffect(() => {
//   const element = myRef.current;

//   const handleNoClickEvent = (e) => {
//     console.log('Custom event was fired in ListenerComponent!', e.detail.message);
//   };

//   if (element) {
//     element.addEventListener('myCustomEvent', handleCustomEvent);
//   }

//   return () => {
//     if (element) {
//       element.removeEventListener('myCustomEvent', handleCustomEvent);
//     }
//   };
// }, []);

const Wrapper = React.forwardRef(({ children, isLoading, loadingSize, styleLoading, noClick = () => { }, ...props }, ref) => (
  <div {...htmlProps(props)} ref={ref}>
    {isLoading ? <DisappearedLoading color={colors.primary.base} style={styleLoading && styleLoading} /> : children}
  </div>
))

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default withStyles(Wrapper)