import React, { useState, useRef } from 'react';
import { Wrapper, Box, Icon } from '../../../../../../../components';
import styled, { keyframes, css } from 'styled-components';
import { useOutsideClick } from '../../../../../../../Hooks';
import Portal from '../../../../../../../components/Portal';

const slideIn = keyframes`
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    100% {
        transform: translateX(20%);
        opacity: 1;
    }
`;

const HiddenWrapper = styled(Wrapper).attrs((props) => ({
    style: {
        opacity: props.isSelected ? 1 : 0,
        transform: props.isSelected ? 'translateX(20%)' : 'translateX(-50%)',
        left: '80%',
    },
}))`
    position: ${(props) => (props.isSelected ? 'static' : 'absolute')};
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
`;

const TagWrapper = styled(Wrapper).attrs((props) => ({
    style: {
        cursor: props.isAbleToUpdate ? 'pointer' : 'default',
    },
}))`
    min-width: fit-content;
    transition: width 0.3s ease-in-out;
    justify-content: center;

    &:hover {
        ${HiddenWrapper} {
            position: ${(props) => props.isAbleToUpdate && 'static'};
            animation: ${(props) =>
                props.isSelected || !props.isAbleToUpdate
                    ? css``
                    : css`
                          ${slideIn} 0.2s forwards
                      `};
        }
    }

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
`;

const TableDropdown = ({ children, tagComponent, isAbleToUpdate, textColor, ...props }) => {
    const dropdownRef = useRef(null);
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    useOutsideClick([dropdownRef], () => {
        if (isOpenDropdown) {
            setTimeout(() => {
                setIsOpenDropdown(false);
            }, 200);
        }
    });

    return (
        <Wrapper position='relative' flexbox>
            <TagWrapper
                ref={dropdownRef}
                position='relative'
                flexbox
                center
                isSelected={isOpenDropdown}
                isAbleToUpdate={isAbleToUpdate}
                onClick={() => isAbleToUpdate && setIsOpenDropdown((prevState) => !prevState)}
                {...props}
            >
                {tagComponent}

                <HiddenWrapper flexbox center isSelected={isOpenDropdown} isAbleToUpdate={isAbleToUpdate}>
                    <Icon name={isOpenDropdown ? 'expand_less' : 'expand_more'} fill={textColor} size={20} />
                </HiddenWrapper>
            </TagWrapper>

            {isOpenDropdown && (
                <Portal triggerRef={dropdownRef} isOpen={isOpenDropdown} customTop={3}>
                    <Box position='absolute' width='fit-content' bgColor='background.07' minWidth='8rem' zIndex='10'>
                        {children}
                    </Box>
                </Portal>
            )}
        </Wrapper>
    );
};

export default TableDropdown;
