import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import { MISC_IMAGES } from '../../../Enviroment';
import { Wrapper, Text } from '../../../components';
import ProfileInfo from '../ProfileInfo';
import { CommentBubble, ChatInput } from './Components';
import { AppContext } from '../../../Context';

const numberNullPropType = PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null, false])])
const stringNullPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, false])])

const propTypes = {

    messages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        authorId: PropTypes.number.isRequired,
        authorName: stringNullPropType,
        authorPhoto: stringNullPropType,

        comment: PropTypes.string.isRequired,
        lastUpdate: PropTypes.string.isRequired,
        read: PropTypes.bool,

        replyId: numberNullPropType,
        replyInfo: PropTypes.shape({
            id: numberNullPropType,
            authorId: numberNullPropType,
            authorName: stringNullPropType,
            comment: stringNullPropType,
        }),

        typeId: PropTypes.number.isRequired,
    })),

    onSendMessage: PropTypes.func,
    onDeletedMessage: PropTypes.func

}

const defaultProps = {
    messages: []
}

const Chat = ({ messages, onSendMessage, onDeletedMessage }) => {

    const { globalContext } = useContext(AppContext)
    const requestUserInfo = globalContext?.user

    const [selectedReplyComment, setSelectedReplyComment] = useState(null)
    const chatControl = { prevMessage: null, currentMessage: null }

    const getDistinctDays = () => {
        return [... new Set(messages.map(message => moment(message?.lastUpdate).format('DD/MM/YYYY')))].reverse()
    }

    const getMessagesFilteredByDay = (day) => {
        return messages?.filter(message => moment(message?.lastUpdate).format('DD/MM/YYYY') === day)
    }

    return (
        <Wrapper flexbox column height='100%' bgColor='background.01'>

            <Wrapper flexbox style={{ flexDirection: 'column-reverse', overflowY: 'scroll' }} height='100%' padding bgImage={`url('${MISC_IMAGES}/chat_bg.png')`}>

                {
                    getDistinctDays().map((day, dayIdx) => {

                        const commentsFilteredByDate = getMessagesFilteredByDay(day)

                        chatControl.prevMessage = null
                        chatControl.currentMessage = null

                        return (
                            <Wrapper key={dayIdx} flexbox column center>

                                <Wrapper flexbox center padding='0.25rem 0.5rem' bgColor='background.06' border corner='rounded' margin>
                                    <Text fontSize='tiny' opacity>{day}</Text>
                                </Wrapper>

                                <Wrapper flexbox column gap width='100%'>
                                    {
                                        commentsFilteredByDate?.map((comment, msgIdx) => {

                                            const isTextComment = [1, 4].includes(comment?.typeId)
                                            const isAuthor = comment?.authorId === requestUserInfo?.id

                                            chatControl.prevMessage = chatControl.currentMessage
                                            chatControl.currentMessage = isTextComment ? comment.authorId : null

                                            return (
                                                isTextComment ? (
                                                    <Wrapper key={msgIdx} flexbox column width='100%' align={isAuthor && 'end'} gap='0.5rem'>

                                                        {
                                                            ((chatControl.prevMessage !== chatControl.currentMessage) || chatControl.prevMessage === null) && (
                                                                <Wrapper flexbox align='end' justify={isAuthor && 'end'} gap fontSize='tiny'
                                                                    style={{ flexDirection: !isAuthor && 'row-reverse' }} width='fit-content'>

                                                                    <Text opacity='0.8'>{comment.authorName}</Text>


                                                                    <ProfileInfo
                                                                        withImage
                                                                        imageProps={{size: "mini"}}
                                                                        id={comment.authorId}
                                                                        src={comment?.authorPhoto}
                                                                        profileType='user'
                                                                    />

                                                                </Wrapper>
                                                            )
                                                        }

                                                        <CommentBubble
                                                            requestUserId={requestUserInfo?.id}
                                                            comment={comment}
                                                            isAuthor={isAuthor}
                                                            isLastComment={(dayIdx === 0) && msgIdx >= (commentsFilteredByDate.length - 2)}
                                                            onSelectedComment={(comment) => setSelectedReplyComment(comment)}
                                                            onDeleteComment={(comment) => onDeletedMessage(comment)}
                                                        />

                                                    </Wrapper>
                                                ) : (
                                                    <Wrapper key={msgIdx} flexbox width='100%' center>
                                                        <Wrapper flexbox gap fontSize='tiny' width='fit-content' center>

                                                            <ProfileInfo
                                                                imageProps={{size: "mini"}}
                                                                id={comment.authorId}
                                                                src={comment?.authorPhoto}
                                                                profileType='user'
                                                                withImage
                                                            />

                                                            <Text opacity='0.9'>{comment.authorName} {comment.comment} • {moment(comment.lastUpdate).format('HH:mm')}</Text>

                                                        </Wrapper>
                                                    </Wrapper>
                                                )
                                            )
                                        })
                                    }
                                </Wrapper>
                            </Wrapper>
                        )
                    })
                }

            </Wrapper>

            <ChatInput
                requestUserInfo={requestUserInfo}
                selectedReplyMessage={selectedReplyComment}
                cancelReplyMessage={() => setSelectedReplyComment(null)}
                onSendMessage={(message) => onSendMessage(message)}
            />

        </Wrapper>
    )
}

Chat.propTypes = propTypes
Chat.defaultProps = defaultProps

export default Chat