import React from "react";
import { Wrapper } from "../../../../../components";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
`;

// Styled Component for the shimmering div
const ShimmerDiv = styled.div`
  width: ${props => props.width && props.width};
  border-radius: ${props => props.radius && props.radius};
  height: ${props => props.height && props.height};
  background: linear-gradient(90deg, #252525 25%, #333333 50%, #252525 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s ease infinite;
`;

const KanbanSkeleton = ({ }) => {

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    return (
        <Wrapper flexbox column width="100%" gap>
            {
                [...Array(getRandomNumber(2, 6)).keys()].map((num, index) => {
                    return (
                        <ShimmerDiv key={index} whdtih='100%' height='6rem' />
                    )
                })
            }
        </Wrapper>
    )
}

export default KanbanSkeleton