const options = {
  flexbox: `
    display: flex;
    flex-direction: row;
  `,

  column: `
    flex-direction: column;
  `,

  flex: `
    flex: 1;
  `,

  wrap: `
    flex-wrap: wrap;
  `,

  self: ({ self }) => `
    align-self: ${self};
  `,

  content: ({ content }) => `
    align-content: ${content};
  `,

  align: ({ align }) => `
    align-items: ${align};
  `,

  justify: ({ justify }) => `
    justify-content: ${justify};
  `,

  center: `
    align-items: center;
    justify-content: center;
  `,
}

export default options
