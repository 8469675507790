const options = {
  attached: {
    center: `
      border-radius: 0;
      border-bottom: 0;
    `,

    top: `
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `,

    bottom: `
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `,

    left: `
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `,

    right: `
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `,
  },

  blocked: `
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  `,
}

export default options
