import { colors, dimensions } from '../../theme'

const { nano, mini, tiny, small, normal, large, big, huge, hugexl, huge2xl, huge3xl } = dimensions.fontSize

const options = {
  fontSize: {
    _: ({ fontSize }) => `font-size: ${fontSize};`,
    nano: `font-size: ${nano};`,
    mini: `font-size: ${mini};`,
    tiny: `font-size: ${tiny};`,
    small: `font-size: ${small};`,
    normal: `font-size: ${normal};`,
    large: `font-size: ${large};`,
    big: `font-size: ${big};`,
    huge: `font-size: ${huge};`,
    hugexl: `font-size: ${hugexl};`,
    huge2xl: `font-size: ${huge2xl};`,
    huge3xl: `font-size: ${huge3xl};`,
  },

  textAlign: ({ textAlign }) => `
    text-align: ${textAlign};
  `,

  thin: `
    font-weight: 250 
  `,

  light: `
    font-weight: 280 
  `,

  regular: `
    font-weight: 330
  `,

  medium: `
    font-weight: 500
 `,

  strong: `
    font-weight: 600;
  `,

  bold: `
    font-weight: 700;
  `,

  black: `
    font-weight: 900;
  `,

  italic: `
    font-style: italic;
  `,

  uppercase: `
    text-transform: uppercase;
  `,

  textTransform: ({ textTransform }) => `
    text-transform: ${textTransform};
  `,

  truncate: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  color: ({ color }) => {
    const inverted = color.indexOf('!') > -1
    const realColor = color.replace('!', '')
    return `color: ${colors.getColor(realColor, inverted)};`
  },
}

export default options
