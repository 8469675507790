import React, { useState, useReducer, useEffect, useRef } from 'react'
import { Wrapper, Icon, Text, Input } from '../../../components';
import PropTypes from 'prop-types'
import { useOutsideClick } from '../../../Hooks';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    to {
      transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-radius: inherit;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #1DC077 0%, #1E1E1E 100%);
        animation: ${rotate} 0.5s infinite linear;
    }

    &:after {
        width: 85%;
        height: 85%;
        background-color: #222;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const propTypes = {

    dataFilter: PropTypes.arrayOf(PropTypes.object),

    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
    })),

    onSort: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),

    resetFilterTrigger: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),

}

const defaultProps = {
    onSort: false,
    resetFilterTrigger: false
}

const initialState = {
    filterOptions: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FILTER':
            return { ...state, filterOptions: action.filterOptions };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const DataFilter = ({ dataFilter, columns, onSort, resetFilterTrigger }) => {

    const filterWindowRef = useRef(null)
    useOutsideClick([filterWindowRef], () => { handleFilterWindow(null); })

    const [state, dispatch] = useReducer(reducer, initialState);
    const [debounceInputValue, setDebounceInputValue] = useState('')
    const [searchInputValue, setSearchInputValue] = useState('')

    const [isLoadingDebounceInput, setIsLoadingDebounceInput] = useState(false)

    const [newFilterWindow, setNewFilterWindow] = useState(false)

    const handleNewFilterWindow = () => {

        if (state?.filterOptions?.openFilter) {

            setNewFilterWindow(false)

            const object = { ...state.filterOptions, openFilter: null }
            dispatch({ type: 'SET_FILTER', filterOptions: object })

            return
        }

        setNewFilterWindow(prevState => { return !prevState })

    }

    const handleFilterWindow = (key) => {
        setNewFilterWindow(false)
        const object = { ...state.filterOptions, openFilter: state?.filterOptions?.openFilter === key ? null : key }
        dispatch({ type: 'SET_FILTER', filterOptions: object })
    }

    function getDistinctValue(key) {
        const uniqueValues = [...new Set(dataFilter.filter(item => matchesSearch(item)).map(value => value[key]))]
        return uniqueValues.filter(value => value !== undefined)
    }

    function matchesFilters(item) {
        const currentFilters = state?.filterOptions?.filters || {}

        if (Object.keys(currentFilters).length === 0) {
            return true;
        }

        for (let key in currentFilters) {
            if (!currentFilters[key].length) continue;

            if (!item.hasOwnProperty(key) || !currentFilters[key].includes(item[key])) {
                return false;
            }
        }

        return true;
    }

    const handleFilterOptions = (data, key) => {
        const currentFilters = state?.filterOptions?.filters || {}

        if (currentFilters[key]) {

            if (currentFilters?.[key]?.some(value => value === data)) {
                currentFilters[key] = currentFilters[key].filter(value => value !== data)
            } else {
                currentFilters[key].push(data)
            }
        } else {

            currentFilters[key] = [data]
        }

        const object = { ...state.filterOptions, filters: currentFilters, openFilter: null }
        dispatch({ type: 'SET_FILTER', filterOptions: object })
    }

    function matchesSearch(item) {
        const searchValue = debounceInputValue.toLowerCase();

        return Object.values(item).some(value =>
            String(value).toLowerCase().includes(searchValue)
        );

    }

    function getFilterKeys() {
        if (state?.filterOptions?.filters) {
            return Object.keys(state?.filterOptions?.filters).filter(key => state?.filterOptions?.filters[key].length > 0)
        }
        return []
    }

    function getFilterValues(filterKey) {
        return state?.filterOptions?.filters[filterKey]
    }

    useEffect(() => {
        onSort && onSort([...dataFilter]?.filter(item => matchesSearch(item) && matchesFilters(item)))
    }, [debounceInputValue, state])

    useEffect(() => {
        setSearchInputValue('')
        setDebounceInputValue('')
        dispatch({ type: 'SET_FILTER', filterOptions: {} })
    }, [resetFilterTrigger])

    useEffect(() => {
        if (!isLoadingDebounceInput) setIsLoadingDebounceInput(true)

        const timeoutId = setTimeout(() => {
            setDebounceInputValue(searchInputValue);
            setIsLoadingDebounceInput(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [searchInputValue]);

    return (
        <Wrapper flexbox column>

            <Wrapper flexbox column corner='default' bgColor='background.02' padding='0.75rem 1rem' gap>

                <Wrapper flexbox gap width='100%' align='center'>
                    <Input
                        icon='search'
                        placeholder='Pesquisar...'
                        width='20%'
                        height='2rem'
                        value={searchInputValue}
                        onChange={(event) => { setSearchInputValue(event.target.value); }}
                    />
                    {
                        isLoadingDebounceInput && (
                            <LoaderWrapper />
                        )
                    }

                </Wrapper>

                <Wrapper flexbox align='center'>
                    <Wrapper flexbox align='center' padding='0.75rem 0.75rem 0.75rem 0rem' gap >
                        <Text fontSize='tiny' opacity='0.7'>Filtros:</Text>

                        <Wrapper flexbox align='center' gap>
                            {
                                getFilterKeys().length > 0 && (
                                    getFilterKeys().map((filter) => {
                                        return (
                                            getFilterValues(filter).map((value, index) => {
                                                return (
                                                    <Wrapper key={index} flexbox bgColor='#295B45' corner='rounded' padding='0.5rem' gap='0.5rem' fontSize='tiny' align='center'>
                                                        <Text regular opacity='0.8'>{columns.find(column => column.key === filter)?.header}:</Text>
                                                        <Text strong opacity='0.8'>{value}</Text>
                                                        <Icon name='close' fill='white' size={15} onClick={() => handleFilterOptions(value, filter)} style={{ cursor: 'pointer' }} />
                                                    </Wrapper>
                                                )
                                            })
                                        )
                                    })
                                )
                            }
                        </Wrapper>

                        <Wrapper flexbox column center position='relative'>

                            <Wrapper flexbox center opacity='0.8' onClick={() => handleNewFilterWindow()}>
                                <Icon name='add' size={25} fill='primary' />
                                <Text color='primary' fontSize='small'>Novo Filtro</Text>
                            </Wrapper>

                            {
                                newFilterWindow && (
                                    <Wrapper ref={filterWindowRef} flexbox column position='absolute' left='0' top='120%' bgColor='background.04' width='200px' zIndex='10'>
                                        {
                                            columns.map((filter, index) => {
                                                return (
                                                    <Wrapper key={index} padding='0.8rem 0.5rem' bgHover='background.06' onClick={() => { handleFilterWindow(filter.key) }}>
                                                        <Text fontSize='small'>{filter.header}</Text>
                                                    </Wrapper>
                                                )
                                            })
                                        }
                                    </Wrapper>
                                )
                            }

                            {
                                state.filterOptions.openFilter && (
                                    <Wrapper ref={filterWindowRef} flexbox column position='absolute' left='0' top='120%' bgColor='background.04'
                                        width='200px' zIndex='10'>
                                        {
                                            getDistinctValue(state?.filterOptions?.openFilter).map((value, index) => {
                                                return (
                                                    <Wrapper key={index} padding='0.8rem 0.5rem' bgHover='background.06'
                                                        onClick={() => { handleFilterOptions(value, state.filterOptions.openFilter) }}>
                                                        <Text fontSize='small'>{value}</Text>
                                                    </Wrapper>
                                                )
                                            })
                                        }
                                    </Wrapper>
                                )
                            }

                        </Wrapper>

                    </Wrapper>

                </Wrapper>

            </Wrapper>

        </Wrapper>
    )
}

DataFilter.propTypes = propTypes
DataFilter.defaultProps = defaultProps

export default DataFilter