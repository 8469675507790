import React from 'react';
import Wrapper from '../../Wrapper';
import Icon from '../../Icon';
import Text from '../../Text';

const Pagination = ({ paginationPages, currentPaginationPage, paginationSize, setCurrentPaginationPage, setPaginationSize, ...props }) => {

    const handlePaginationPageChange = (paginationChangeIndex) => {
        if (isNaN(paginationChangeIndex)) return
        if (paginationChangeIndex < 0 || paginationChangeIndex > paginationPages - 1) return
        setCurrentPaginationPage(paginationChangeIndex)
    }

    const handlePaginationSizeChange = (paginationSize) => {
        setPaginationSize(paginationSize);
        setCurrentPaginationPage(0);
        return
    }

    return (
        <Wrapper flexbox justify='space-between' padding='0.5rem' {...props} fontSize='tiny'>

            <Wrapper flexbox align='center' justify='center' gap='0.5rem'>

                <Icon fill='white' name='chevron_left' padding='0.25rem' style={{ cursor: 'pointer' }}
                    onClick={() => handlePaginationPageChange(currentPaginationPage - 1)} />

                <input className='form-control' type='text' value={currentPaginationPage + 1}
                    onChange={(event) => handlePaginationPageChange(event?.target?.value - 1)} style={{ width: '5rem' }}></input>

                <Text>de {paginationPages}</Text>

                <Icon fill='white' name='chevron_right' padding='0.25rem' style={{ cursor: 'pointer' }}
                    onClick={() => handlePaginationPageChange(currentPaginationPage + 1)} />

            </Wrapper>

            <Wrapper flexbox align='center' justify='center' gap='0.5rem'>

                <Wrapper flexbox style={{ whiteSpace: 'nowrap' }}>Itens por página:</Wrapper>

                <select style={{ width: '5rem' }} className='form-control' value={paginationSize}
                    onChange={event => handlePaginationSizeChange(event?.target?.value)}>
                    {[10, 25, 50, 100, 200].map(
                        value => <option key={value} value={value}>{value}</option>
                    )}
                </select>

            </Wrapper>

        </Wrapper>
    )
}

export default Pagination