import dimensions from '../dimensions'

const { tiny, small, normal, large, big, huge } = dimensions.gap

const options = {
    gap: {
        _: ({ gap }) => `gap: ${gap};`,
        true: `gap: ${normal};`,
        tiny: `gap: ${tiny};`,
        small: `gap: ${small};`,
        normal: `gap: ${normal};`,
        large: `gap: ${large};`,
        big: `gap: ${big};`,
        huge: `gap: ${huge};`,
    },
}

export default options
