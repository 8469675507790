import React from 'react';
import Wrapper from '../Wrapper';
import Text from '../Text';

const Tag = ({ display, color, ...props }) => {
    return (
        <Wrapper flexbox center padding='0.125rem 0.25rem' corner='default' {...props}>
            <Text color={color}>{display}</Text>
        </Wrapper>
    )
}

export default Tag