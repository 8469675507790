import { colors } from './../../theme'

const options = {
  bgColor: ({ bgColor }) => {
    const inverted = bgColor.indexOf('!') > -1
    const realColor = bgColor.replace('!', '')
    return `background-color: ${colors.getColor(realColor, inverted)};`
  },
  bgImage: ({ bgImage }) => {
    return `background-image: ${bgImage};`
  },
  bgHover: ({ bgHover }) => {
    const inverted = bgHover.indexOf('!') > -1
    const realColor = bgHover.replace('!', '')
    return `&:hover { background-color: ${colors.getColor(realColor, inverted)}; }`
  },
}

export default options
