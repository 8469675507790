import React from 'react';
import { Wrapper } from '../../../../../components';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
`;

// Styled Component for the shimmering div
const ShimmerDiv = styled.div`
  width: ${props => props.width && props.width};
  border-radius: ${props => props.radius && props.radius};
  height: ${props => props.height && props.height};
  background: linear-gradient(90deg, #252525 25%, #333333 50%, #252525 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s ease infinite;
`;

const TableSkeleton = ({ columns = 4 }) => {

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    return (
        <Wrapper flexbox column width='100%' gap='2rem' padding>
            {
                [...Array(getRandomNumber(2, 6)).keys()].map((num, index) => {
                    return (
                        <Wrapper key={index} flexbox align='center' justify='space-between'>
                            <Wrapper flexbox align='center' gap>
                                <ShimmerDiv width='40px' height='40px' radius='50%'></ShimmerDiv>
                                <ShimmerDiv width='6rem' height='1.3rem' radius='3px'></ShimmerDiv>
                            </Wrapper>
                            {
                                [...Array(columns).keys()].map(col => {
                                    return (
                                        <ShimmerDiv key={`col_${col}`} width='6rem' height='1.3rem' radius='3px'></ShimmerDiv>
                                    )
                                })
                            }
                        </Wrapper>
                    )
                })
            }
        </Wrapper>
    )
}

export default TableSkeleton