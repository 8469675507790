import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Text, Icon } from '../../../components'
import styled from 'styled-components'
import { LEGACY_URL } from '../../../Enviroment'

const propTypes = {
    onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    xAxisDirection: PropTypes.string,
    options: PropTypes.array,
    labelOut: PropTypes.string,
    labelIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.elementType]),
}
const defaultProps = {
    xAxisDirection: 'left',
    options: [],
    labelOut: 'Label',
    labelIcon: false,
    onClick: false,
}

const Dropdown = styled(Wrapper).attrs(props => ({
    style: {

        left: props.xAxisDirection === 'left' && '-20%',
        right: props.xAxisDirection === 'right' && '-20%',
        transform: props.isHovered && 'translateY(0)',
        opacity: props.isHovered && '1',
        visibility: props.isHovered && 'visible',

    }
}))`
    width: 250px;
    top: 100%;

    position: absolute;
    color: white;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.065s ease, opacity 0.02s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 12px 12px;
`
const Submenu = ({ children, ...props }) => {
    const [isHovered, setHovered] = useState(false);

    const getOptionPath = (option) => {
        const optPath = option.path

        if (optPath.includes('http')) {
            return optPath
        } else if (option.isLegacy) {
            return `${LEGACY_URL}/${optPath}`
        }

        return optPath
    }
    const isUunNestedLinkSelected = props.options?.some((option) => option?.id === props?.selectedPageId);

    return (
        <Wrapper flexbox position='relative' height='100%' align='center' style={{ 'cursor': 'default' }}
            onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>

            <Wrapper flex flexbox align='center' gap='small' opacity={isUunNestedLinkSelected ? '1' :  '0.5'} opacityHover='1'>

                {
                    props.labelIcon &&
                    <Icon name={props.labelIcon} size={20} fill='white'></Icon>
                }

                <Text color='white' fontSize='small' regular>{props.labelOut}</Text>
                <Icon fill='white' size={18} name='expand_more'></Icon>

            </Wrapper>

            <Dropdown isHovered={isHovered} xAxisDirection={props.xAxisDirection} bgColor='background.01' padding='8px 0'>
                {

                    props.options.map((option, index) => {
                        return (
                            <Wrapper key={index} padding='1rem 1.5rem' opacity={props?.selectedPageId === option?.id ? '1' :  '0.5'} opacityHover='1' fontSize='small' regular
                                onClick={() => props.onClick(getOptionPath(option), (option.isLegacy || option.path.includes('http')))}>
                                {typeof option.display === 'function' ? option.display() : option.display}
                            </Wrapper>
                        )
                    })
                }
            </Dropdown>
        </Wrapper>
    )
}

Submenu.propTypes = propTypes
Submenu.defaultProps = defaultProps

export default Submenu
