import React, { useEffect, useState } from 'react'
import { Wrapper, Modal, Text, Icon, Button } from '../../../components'
import { LEARN_MORE_IMAGES } from '../../../Enviroment'
import styled from 'styled-components'

const SlidesContainer = styled(Wrapper)`
	display: flex;
	overflow-x: hidden;
	transition: transform 0.5s ease-in-out;
`

const LearMoreModal = ({ isOpen, closeModal, pageInfo }) => {
	const [selectedModalPage, setSelectedModalPage] = useState(0)

	const totalWidth = pageInfo?.length * 40

	const calculateTranslateX = () => {
		return `-${selectedModalPage * 40}vw`
	}

	function getSlideDescription(slide) {
		const spotlightKey = '<!#spotlight#!>'
		const spotlightValues = slide.spotlight_text
		let spotlightCounter = 0

		const splittedDescription = slide?.description?.split(' ')

		return (
			<Wrapper flexbox gap='0.3rem' style={{ flexWrap: 'wrap' }} center>
				{splittedDescription?.map((word, index) => {
					const isSpotlight = word === spotlightKey
					spotlightCounter += isSpotlight ? 1 : 0

					return (
						<Text key={index} color={isSpotlight && 'primary'}>
							{isSpotlight
								? spotlightValues[spotlightCounter - 1]
								: word}
						</Text>
					)
				})}
			</Wrapper>
		)
	}

	useEffect(() => {
		setSelectedModalPage(0)
	}, [isOpen])

	return (
		<Modal open={isOpen} close={closeModal}>
			<Wrapper flexbox column bgColor='background.03' width='40vw'>

				<Wrapper flexbox column style={{ overflowX: 'hidden' }} gap='huge' padding>

					<Wrapper style={{ alignSelf: 'end' }} onClick={closeModal}>
						<Text color='text.50'>Pular</Text>
					</Wrapper>

					<SlidesContainer flexbox width={`${totalWidth}vw`} style={{ transform: `translateX(${calculateTranslateX()})` }}>
						{
							pageInfo?.map((slide, index) => (
								<Wrapper key={index} flexbox column width='40vw' padding center gap='huge'>

									{
										slide?.image && (
											<img
												style={{
													height: '20rem',
													width: 'auto',
												}}
												src={`${LEARN_MORE_IMAGES}/${slide?.image}.png`}
											/>
										)
									}

									<Wrapper flexbox column gap='big' maxWidth='80%' center>
										<Text textAlign='center' fontSize='big' strong>{slide?.title}</Text>
										{getSlideDescription(slide)}
									</Wrapper>
								</Wrapper>
							))
						}
					</SlidesContainer>
				</Wrapper>

				<Wrapper flexbox justify='space-between' bgColor='background.06' align='center' padding='horizontal'>

					<Wrapper flexbox align='center' opacity={selectedModalPage === 0 ? '0' : '1'}
						onClick={() => setSelectedModalPage(selectedModalPage - 1)} style={{ pointerEvents: selectedModalPage === 0 && 'none' }}>
						<Icon name='chevron_left' size={30} fill='white' />
						<Text fontSize='small'>ANTERIOR</Text>
					</Wrapper>

					<Wrapper flexbox gap padding='1rem 0' style={{ alignSelf: 'center' }}>
						{
							pageInfo?.map((bullet, index) => {
								const isSelected = selectedModalPage === index
								return (
									<Wrapper key={index} flexbox corner='circle' padding='0.2rem' style={{ outline: isSelected && '1px solid white' }}
										onClick={() => setSelectedModalPage(index)}>
										<Wrapper
											corner='circle'
											height='0.7rem'
											width='0.7rem'
											bgColor={isSelected ? 'primary' : 'background.01'}
										/>
									</Wrapper>
								)
							})
						}
					</Wrapper>

					{
						selectedModalPage === pageInfo?.length - 1 ? (
							<Button flexbox color='blue' height='fit-content' onClick={closeModal}>
								<Text>Começar!</Text>
							</Button>
						) : (
							<Wrapper flexbox align='center' onClick={() => setSelectedModalPage(selectedModalPage + 1)}
								opacity={selectedModalPage === pageInfo?.length - 1 ? '0' : '1'}>
								<Text fontSize='small'>PRÓXIMO</Text>
								<Icon name='chevron_right' size={30} fill='white' />
							</Wrapper>
						)
					}
				</Wrapper>

			</Wrapper>
		</Modal>
	)
}

export default LearMoreModal
