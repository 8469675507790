import React from 'react'
import PropTypes from 'prop-types'
import { omit, keys } from 'ramda'

import { colors } from '../../assets/theme'
import Wrapper from '../Wrapper'
import withStyles, { props } from './styles'

const propTypes = {
    /** A button can have an icon */
    icon: PropTypes.string,

    /** A button can have different sizes */
    size: PropTypes.oneOf(props.sizes),

    /** A button can have different corners */
    corner: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf(props.corners)
    ]),

    /** A button can have different kinds */
    kind: PropTypes.oneOf(props.kinds),

    /** A button can have different colors */
    color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /** A button can be fluid */
    fluid: PropTypes.bool,

    /** A button can be flex */
    flex: PropTypes.bool,

    /** A button can be loading */
    isLoading: PropTypes.bool,

    margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    height: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /**
     * Called after user's click.
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClick: PropTypes.func,

    /** A button can have different colors */
    transition: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

const defaultProps = {
    icon: null,
    color: 'default',
    kind: 'default',
    size: 'normal',
    corner: false,
    fluid: false,
    isLoading: false,
    flex: false,
    center: false,
    margin: false,
    height: false,
}

const htmlProps = props => omit(keys(defaultProps), props)

const Button = ({ children, isLoading, icon, color, ...props }) => (
    <button {...htmlProps(props)} disabled={props.disabled || isLoading}>
        <Wrapper flexbox>
            <Wrapper flex flexbox center gap>
                {children}
            </Wrapper>
            {!isLoading && icon && (
                <Wrapper flexbox align="center" justify="center" padding={children && '0 0 0 8px'}>
                </Wrapper>
            )}
        </Wrapper>
    </button>
)

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default withStyles(Button)
