import { colors } from './../../theme'

const options = {
  color: ({ color }) => {
    const inverted = color.indexOf('!') > -1
    const realColor = color.replace('!', '')
    return `color: ${colors.getColor(realColor, inverted)};`
  },
}

export default options
