const dimensions = {
    padding: '1rem',
    borderRadius: '.28571429rem',

    gap: {
        tiny: '0.125rem',
        small: '0.25rem;',
        normal: '0.5rem',
        large: '0.75rem',
        big: '1rem',
        huge: '1.5rem',
    },

    fontSize: {
        nano: '0.64rem',
        mini: '0.75rem',
        tiny: '0.80rem',
        small: '0.875rem',
        normal: '1rem',
        large: '1.25rem',
        big: '1.563rem',
        huge: '1.953rem',
        hugexl: '2.441rem',
        huge2xl: '3.052rem',
        huge3xl: '3.815rem',
    },
}

export default dimensions
