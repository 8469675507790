import React, { useEffect, useState } from 'react';
import { Wrapper, Icon, Text, Tooltip } from '../../components';
import styled, { keyframes } from 'styled-components';
import EventBus from '../EventBus';
import { colors } from '../../assets/theme';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ResponseWrapper = styled(Wrapper)`
  width: 100%;
  animation: ${slideIn} 0.5s ease forwards;
  transition: opacity 0.5s ease-in-out;
`;

const FloatWrapper = styled(Wrapper)`
    position: absolute;

    height: fit-content;
    width: 80%;

    top: 3%;
    left: 50%;

    transform: translate(-50%, 0);

    z-index: 9999999;
`

const HttpResponseDisplayManager = ({ }) => {

    const [httpResponses, setHttpResponses] = useState([])
    const warningRequestsCodes = [203, 206, 207]

    const httpResponsesStatusStyle = [
        { verifyStatus: (statusCode) => statusCode === 200, icon: 'done', color: 'success' },
        { verifyStatus: (statusCode) => warningRequestsCodes.includes(statusCode), icon: 'warning', color: 'warning' },
        { verifyStatus: (statusCode) => ![200, ...warningRequestsCodes].includes(statusCode), icon: 'error', color: 'danger' }
    ]

    const sendHttpResponse = (response) => {
        setHttpResponses(prevState => [response, ...prevState]);
    };

    const dismissHttpResponse = (responseId) => {
        setHttpResponses(prevState => prevState.filter(item => item.uuid !== responseId));
    };

    useEffect(() => {
        EventBus.on('send-http-response', sendHttpResponse);

        EventBus.on('dismiss-http-response', dismissHttpResponse);

        return () => {
            EventBus.off('send-http-response', sendHttpResponse);
            EventBus.off('dismiss-http-response', dismissHttpResponse);
        };
    }, []);

    const handleDismiss = (responseId) => {
        setHttpResponses((prevState) =>
            prevState.map((item) =>
                item.uuid === responseId ? { ...item, dismissed: true } : item
            )
        );
    };

    return (
        <FloatWrapper flexbox column gap>

            {
                httpResponses.map(response => {
                    const responseStatusStyle = httpResponsesStatusStyle.find(item => item.verifyStatus(response.status))

                    return (
                        <ResponseWrapper
                            key={response.uuid}
                            width='100%'
                            onAnimationEnd={() => handleDismiss(response.uuid)}
                            corner='default'
                            bgColor='background.02'
                        >
                            <Wrapper flexbox width='100%' justify='space-between' align='center' padding='0.5rem'
                                bgColor={`${colors.getColor(responseStatusStyle.color)}33`} corner='default'>

                                <Wrapper flexbox gap align='center' width='70%'>

                                    <Icon name={responseStatusStyle.icon} fill={responseStatusStyle.color} size={30} />

                                    <Wrapper flexbox column justify='center' gap='small'>

                                        <Text>{response.message}</Text>

                                        {
                                            (!response.ok && response.possibleOrigin) && (
                                                <Text fontSize='small' color='text.80'>{response.possibleOrigin}</Text>
                                            )
                                        }

                                    </Wrapper>

                                </Wrapper>

                                <Wrapper onClick={() => dismissHttpResponse(response.uuid)}>
                                    <Icon name='close' fill='white' size={25} />
                                </Wrapper>

                            </Wrapper>

                        </ResponseWrapper>
                    )
                })
            }
        </FloatWrapper>
    )
}

export default HttpResponseDisplayManager

