import React from 'react';
import Loading from '../../../Loading';
import { DataTable, Wrapper } from '../../../../../components';

const AllocationHistory = ({ customer, viewInfo, isLoading }) => {

    const columns = [
        { key: 'date', header: 'Data', format: 'date', filter: true, sortable: true },
        { key: 'advisorName', header: 'Assessor', filter: true },
        { key: 'productCategory', header: 'Categoria', filter: true },
        { key: 'asset', header: 'Produto', colSpan: 2, format: 'truncate', filter: true },
        { key: 'value', header: 'Valor', format: 'currency', filter: true, sortable: true },
        { key: 'revenue', header: 'Receita', format: 'currency', filter: true, sortable: true },
    ]

    return (
        !isLoading ? (
            <Wrapper flexbox fontSize='tiny'>
                <DataTable
                    columns={columns}
                    data={viewInfo}
                    pagination={10}
                />
            </Wrapper>
        ) : <Loading type='table' />
    )
}

export default AllocationHistory