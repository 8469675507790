import React, { useEffect, useState, useRef } from 'react';
import { Wrapper, Text, Icon } from '../../../../../components';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../../assets/theme';
import moment from 'moment';
import { useOutsideClick } from '../../../../../Hooks';

const popIn = keyframes`
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
`;

const popOut = keyframes`
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0);
      opacity: 0;
    }
`;

const BubbleWrapper = styled(Wrapper).attrs(props => ({
    style: {
        backgroundColor: colors.getColor('background.05'),
        backgroundImage: props.isAuthor && 'linear-gradient(to right, #005C4B, #025144)',
        borderRadius: `${props.isAuthor ? '5px 0px' : '0px 5px'} 5px 5px`,
        overflow: props.isOpen ? 'visible' : 'hidden'
    }
}))`
    position: relative;
    width: fit-content;
    max-width: 60%;
    justify-content: space-between;
    align-items: end;
    box-shadow: 1px 2px 6.1px 6px rgba(0, 0, 0, 0.10);
`

const ExpandMoreWrapper = styled(Wrapper).attrs(props => ({
    style: {
        backgroundImage: `linear-gradient(245deg, ${props.isAuthor ?
            '#025144 50%, transparent 80%' : `${colors.getColor('background.05')} 50%, transparent 80%`})`,
        borderRadius: `${props.isAuthor ? '5px 0px' : '0px 5px'} 5px 5px`,
        opacity: props.isHovered ? '1' : '0',
        transform: props.isHovered ? 'translateX(0)' : 'translateX(100%)',
    }
}))`
    position: absolute;
    width: 2.2rem;
    right: 0;
    top: 0;
    z-index: 5;
    justify-content: center;
    transition: transform 0.1s ease, opacity 0.2s ease;
`;

const OptionsWrapper = styled(Wrapper).attrs(props => ({
    style: {
        backgroundColor: colors.getColor('background.08'),
        opacity: '0',
        zIndex: props.isOpen ? '50' : '0',
        right: props.isAuthor ? '2rem' : '-9.5rem',
        pointerEvents: !props.isOpen && 'none',
        top: props.isLastComment ? (props.isAuthor ? '-4rem' : '-2rem') : '1rem',
    }
}))`
    position: absolute;
    width: 10rem;
    boxShadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    animation: ${props => props.isOpen ? popIn : popOut} 0.2s ease-in-out forwards;
`

const CommentTextWrapper = styled(Wrapper)`
    max-width: 90%;
    padding: 6px 7px 8px 9px;
    overflo-wrap: break-word;
    word-wrap: break-word;
`

const CommentBubble = ({ requestUserId, comment, isAuthor, isLastComment, onSelectedComment, onDeleteComment, ...props }) => {

    const optionsRef = useRef(null)

    useOutsideClick([optionsRef], () => setIsOpenOptions(false))

    const [isOpenOptions, setIsOpenOptions] = useState(false)
    const [isHovered, setIsHovered] = useState(false);

    async function deleteComment() {
        setIsOpenOptions(false)
        onDeleteComment(comment)
    }

    function replyComment() {
        const chatInput = document.getElementById('chat_comment')
        chatInput.focus()

        setIsOpenOptions(false)
        onSelectedComment(comment);
    }

    const isSelfReply = () => {
        return comment.replyInfo.authorId === requestUserId
    }

    return (
        <BubbleWrapper ref={optionsRef} flexbox isAuthor={isAuthor} border={!isAuthor} gap margin='horizontal'
            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} isOpen={isOpenOptions}>

            {
                !comment.deleted && (
                    <ExpandMoreWrapper flexbox isAuthor={isAuthor} isHovered={isHovered || isOpenOptions} corner='rounded'>

                        <Wrapper flexbox onClick={() => setIsOpenOptions(!isOpenOptions)} height='fit-content'>
                            <Icon name='expand_more' fill={'#B0AAA9'} size={25} />
                        </Wrapper>

                    </ExpandMoreWrapper>
                )
            }


            <OptionsWrapper flexbox column isAuthor={isAuthor} isOpen={isOpenOptions}
                isLastComment={isLastComment} corner='default' padding='0.5rem 0' fontSize='small'>

                <Wrapper flexbox bgHover='background.05' onClick={() => replyComment()} padding='0.5rem'>
                    <Text>Responder</Text>
                </Wrapper>

                {
                    isAuthor && (
                        <Wrapper flexbox bgHover='background.05' onClick={() => deleteComment()} padding='0.5rem'>
                            <Text>Excluir Mensagem</Text>
                        </Wrapper>
                    )
                }

            </OptionsWrapper>

            <Wrapper flexbox justify='space-between' align='end' gap>

                {
                    !comment.deleted ? (
                        <CommentTextWrapper flexbox column gap>

                            {
                                comment.typeId === 4 && (
                                    <Wrapper flexbox column width='100%' bgColor={isAuthor ? '#054238' : 'background.02'} minWidth='8rem'
                                        padding='0.5rem' corner='default' fontSize='small' gap='small'
                                        style={{ borderLeft: `4px solid ${isSelfReply() ? `${colors.getColor('primary')}BF` : '#53BDEBBF'}` }}>

                                        <Text opacity='0.8' color={isSelfReply() ? 'primary' : '#53BDEB'} medium>
                                            {isSelfReply() ? 'Você' : comment.replyInfo.authorName}
                                        </Text>

                                        <Wrapper flexbox maxHeight='7rem' style={{ overflowY: 'scroll' }}>
                                            <Text opacity='0.5' color='white' padding='right'>{comment.replyInfo.comment}</Text>
                                        </Wrapper>

                                    </Wrapper>
                                )
                            }

                            <Text fontSize='small' opacity='0.9'>
                                {
                                    comment.comment.split('\n').map((line, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Text>

                        </CommentTextWrapper>

                    ) : (
                        <CommentTextWrapper flexbox gap='small' opacity='0.6' align='center'>
                            <Icon name='block' size={20} fill='white' />
                            <Text style={{ fontStyle: 'italic' }} fontSize='tiny'>Mensagem Apagada</Text>
                        </CommentTextWrapper>
                    )
                }
                
                <Text padding='0.2rem' fontSize='10px' align='end' opacity='0.6'>{moment(comment.lastUpdate).format('HH:mm')}</Text>

            </Wrapper>

        </BubbleWrapper>
    )
}

export default CommentBubble