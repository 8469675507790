import { useState, useEffect } from "react"

function useImageCheck(src, callback) {
    const [isValidSrc, setIsValidSrc] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;

        const handleLoad = () => {
            setIsValidSrc(true);
        };

        const handleError = () => {
            setIsValidSrc(false);
            callback();
        };

        img.addEventListener('load', handleLoad);
        img.addEventListener('error', handleError);

        // Cleanup
        return () => {
            img.removeEventListener('load', handleLoad);
            img.removeEventListener('error', handleError);
        };
    }, [src]);

    return isValidSrc;
}

export default useImageCheck