import React from 'react';
import { Icon, Text, Wrapper } from '../../../components';

const MaintenancePage = () => {

    return (

        <Wrapper flexbox center column padding='6rem 0' width='100%'>

            <Wrapper flexbox center column gap='1rem' width='30rem' textAlign='center' height='20rem'>

                <Icon size={140} opacity='0.4' fill="white" name="construction" />
                <Text strong fontSize='huge'>EM MANUTENÇÃO</Text>
                <Text fontSize='small' opacity='0.7'>Esta página está passando por alterações, por favor tente denovo mais tarde.</Text>

            </Wrapper>

        </Wrapper>

    )
};

export default MaintenancePage;
