import React from 'react'

import Standalone from './Standalone'
import WithLabel from './WithLabel'

const Icon = props => {
  return props.children ? <WithLabel {...props} /> : <Standalone {...props} />
}

export default Icon
