import * as XLSX from 'xlsx';
import moment from 'moment'
import { saveAs } from 'file-saver';
import { getCurrentMonth, getCurrentWeek } from '../../formatters/dates';

async function removeElementsOpacity(timeDuration = 100, classKey = 'opacity_card') {
    setTimeout(() => {
        const elements = document.querySelectorAll(`.${classKey}`);
        let time = 0;
        elements.forEach((elem, index) => {
            setTimeout(() => {
                // elem.classList.remove('tw-opacity-0');
                elem.style.opacity = 1
            }, time);
            time += timeDuration;
        });
    }, 10);
}

const paceColor = {
    'inPace': {
        'filled': '#A79536',
        'noFilled': '#ed9302',
    },
    'highPace': {
        'filled': '#1A533A',
        'noFilled': '#1dc077',
    },
    'outPace': {
        'filled': '#752424',
        'noFilled': '#fb3640',
    },
}

function isSpecialist(sectorId) {
    return [3, 13, 14, 26, 27, 39, 41, 42, 43, 45].includes(sectorId)
}

function isTM(roleId) {
    return [43, 44, 124].includes(roleId)
}

function isAdmin(sectorId) {
    return [1].includes(sectorId)
}

function isConsultant(sectorId) {
    return [16].includes(sectorId)
}

function isCoreSector(sectorId, includeRms = false, includeBankers = false) {
    return [10, ...(includeRms ? [33] : []), ...(includeBankers ? [34] : [])].includes(sectorId)
}

function getPaceColor(value, selectedMonth) {
    let diaAtual = new Date();
    let ultimoDia = new Date(diaAtual.getFullYear(), diaAtual.getMonth() + 1, 0);
    let paceMonth = (diaAtual.getDate() / ultimoDia.getDate() * 100);

    if (selectedMonth !== (diaAtual.getMonth() + 1)) {
        paceMonth = 100;
    }

    return value >= 100 ? paceColor['highPace'] : paceMonth > value
        ? paceColor['outPace']
        : paceColor['inPace'];
}

function excelBuffer(binaryString) {
    const buffer = new ArrayBuffer(binaryString.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < binaryString.length; i++) {
        view[i] = binaryString.charCodeAt(i) & 0xFF;
    }
    return buffer;
}

function createWorkBook(title) {
    const wb = XLSX.utils.book_new();

    wb.Props = {
        Title: title,
        Subject: 'details',
        Author: 'Desenvolvimento Ável',
        CreatedDate: new Date(),
    };

    wb.SheetNames.push(title);

    return wb
}


function downloadExcel(data, headers, title) {
    const wb = createWorkBook(title.workbook)

    let header = headers.map(header => header.name);

    const fullData = data.map(row => {

        const obj = headers.reduce((prev, curr) => {
            if (curr?.format) {
                prev[curr.name] = curr.format(row);
            } else {
                prev[curr.name] = row[curr.key] || '-';
            }
            return prev;
        }, {})

        return obj

    });

    const flatData = fullData.flat();
    wb.Sheets[title.workbook] = XLSX.utils.json_to_sheet(flatData, header);
    const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary',
    });

    setTimeout(() => {
        window.saveAs(new Blob([excelBuffer(wbout)], { type: 'application/octet-stream' }),
            `${title.file}.xlsx`);
    }, 200);
}

function DownloadPdf(path, folder_name) {
    // Trigger the download
    saveAs(path, folder_name + '.pdf');
}

function getFirstAndLastDay(year, month) {
    var firstDay = new Date(year, month - 1, 1);
    var lastDay = new Date(year, month, 0);
    return { firstDay, lastDay };
}

function getCustomersGroupedByLinkedAccounts(customers) {
    const referenceArray = customers.map(item => ({ ...item }))

    let visited = new Set();
    let result = [];
    let soloCustomers = [];
    let colorIndex = 0;
    const groupColors = ['#43E97A', '#469DF5', '#A134EF', '#EC3E3E', '#FCDE3F'];

    function getColor() {
        return groupColors[colorIndex]; // Get current color without incrementing
    }

    function incrementColorIndex() {
        colorIndex = (colorIndex + 1) % groupColors.length; // Increment and loop back when at the end
    }

    function bfs(start) {
        let queue = [start];
        let group = [];
        let color = getColor();

        while (queue.length > 0) {
            let current = queue.shift();
            if (current) {
                if (!visited.has(current.customerCode)) {
                    visited.add(current.customerCode);
                    current.groupColor = color;
                    group.push(current);

                    current?.linkedAccounts?.forEach(linkedId => {
                        if (!visited.has(linkedId)) {
                            let linkedCustomer = referenceArray.find(c => c.customerCode === linkedId);
                            queue.push(linkedCustomer);
                        }
                    });
                }
            }
        }

        if (group.length > 1) {
            group.forEach((item, index) => {
                if (index === group.length - 1) {
                    item.isLastGroupMember = true
                    return
                }

                item.isLastGroupMember = false
            })
            result = result.concat(group);
            incrementColorIndex();
        } else if (group.length === 1) {
            soloCustomers.push(group[0]);
        }
    }

    referenceArray.forEach(customer => {
        if (!visited.has(customer.customerCode)) {
            bfs(customer);
        }
    });

    soloCustomers.forEach(customer => {
        customer.groupColor = '#ADA8A8'
        result.push(customer);
    });

    return result;
}

function getRangeDate(key) {
    const today = moment().format('YYYY-MM-DD')

    switch (key) {
        case 'day': {
            return [today, today]
        }
        case 'week': {
            return getCurrentWeek().map(date => date.format('YYYY-MM-DD'))
        }
        case 'month': {
            return getCurrentMonth().map(date => date.format('YYYY-MM-DD'))
        }
        default: {
            return [today, today]
        }
    }
}

export {
    removeElementsOpacity,
    getPaceColor,
    downloadExcel,
    getFirstAndLastDay,
    DownloadPdf,
    getCustomersGroupedByLinkedAccounts,
    isSpecialist,
    isAdmin,
    isTM,
    isCoreSector,
    isConsultant,
    getRangeDate
}