const options = {
  corner: {
    _: ({ corner }) => `
      border-radius: ${corner};
    `,
    square: `border-radius: none;`,
    default: `border-radius: 3px;`,
    bowed: `border-radius: 6px;`,
    rounded: `border-radius: 15px;`,
    circle: `border-radius: 50%;`,
  },
}

export default options
