import Row from './Row';

const TableBody = ({ tableData, fullData, columns, onUpdateValue, hasDelete, formatController, dispatch, isSelectable, rowProps,
    handleSelectedRows, handleDeletedRows, deletedRows, dropdownColumn }) => {

    const showColumns = columns?.filter(column => column?.show !== false);
    const numberOfColumns = showColumns.length - 1;

    return (
        tableData.filter(item => !hasDelete || (hasDelete && !deletedRows.includes(item.uuid))).map((item, rowIndex) => {
            return (
                <Row
                    key={`${item.uuid}_row`}
                    item={item}
                    rowIndex={rowIndex}
                    rowProps={rowProps}

                    columns={columns}
                    showColumns={showColumns}
                    dropdownColumn={dropdownColumn}
                    numberOfColumns={numberOfColumns}

                    hasDelete={hasDelete}
                    formatController={formatController}
                    isSelectable={isSelectable}

                    onUpdateValue={onUpdateValue}
                    fullData={fullData}
                    dispatch={dispatch}

                    handleSelectedRows={handleSelectedRows}
                    handleDeletedRows={handleDeletedRows}
                />
            )
        })
    )
}

export default TableBody