import React, { useState } from 'react'
import { omit, keys } from 'ramda'
import withStyles, { props } from './styles'
import { Wrapper } from '../../components'
import PropTypes from 'prop-types'

const propTypes = {

    variant: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(props.variants)]),

    bgColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    corner: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])

}

const defaultProps = {
    variant: false,
    bgColor: 'background.02',
    corner: 'default',
}

const Box = React.forwardRef(({ children, bgColor, ...props }, ref) => {
    return (
        <Wrapper ref={ref} {...props}>
            {children}
        </Wrapper>
    );
});

Box.propTypes = propTypes
Box.defaultProps = defaultProps

export default withStyles(Box)