import { keys } from 'ramda'

const sizes = {
    small:
        `height: 5px;`,
    default:
        `height: 7px;`,
    large:
        `height: 9px;`
}

export const props = {
    sizes: keys(sizes),
}