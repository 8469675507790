import dimensions from '../dimensions'

const size = dimensions.padding

const options = {
  margin: {
    _: ({ margin }) => `margin: ${margin};`,
    true: `margin: ${size};`,
    vertical: `margin: ${size} 0;`,
    horizontal: `margin: 0 ${size};`,
    top: `margin-top: ${size};`,
    bottom: `margin-bottom: ${size};`,
    left: `margin-left: ${size};`,
    right: `margin-right: ${size};`,
    noTop: `margin: 0 ${size} ${size};`,
    noBottom: `margin: ${size} ${size} 0;`,
    noLeft: `margin: ${size} ${size} ${size} 0;`,
    noRight: `margin: ${size} 0 ${size} ${size};`,
  },
}

export default options
