import { dimensions } from '../../theme'

const size = dimensions.borderRadius

const options = {
  borderRadius: {
    _: ({ borderRadius }) => `border-radius: ${borderRadius};`,
    true: `border-radius: ${size}`,
    top: `border-radius: ${size} ${size} 0 0`,
    bottom: `border-radius: 0 0 ${size} ${size}`,
    left: `border-radius: ${size} 0 0 ${size}`,
    right: `border-radius: 0 ${size} ${size} 0`,
    topLeft: `border-radius: ${size} 0 0 0`,
    topRight: `border-radius: 0 ${size} 0 0`,
    bottomLeft: `border-radius: 0 0 0 0 ${size}`,
    bottomRight: `border-radius: 0 0 ${size} 0`,
  },
}

export default options
