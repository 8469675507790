import { keyframes } from 'styled-components'
import colors from './colors'

const animations = {
  tada: keyframes`
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    10%, 20% {
      -webkit-transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, -3deg);
    }

    30%, 50%, 70%, 90% {
      -webkit-transform: scale3d(1.5, 1.5, 1.5) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.5, 1.5, 1.5) rotate3d(0, 0, 1, 3deg);
    }

    40%, 60%, 80% {
      -webkit-transform: scale3d(1.5, 1.5, 1.5) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.5, 1.5, 1.5) rotate3d(0, 0, 1, -3deg);
    }

    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  `,

  fadeOutLeft: keyframes`
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      display: none;
    }
  `,

  fadeOutUp: keyframes`
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  `,

  fadeOutDown: keyframes`
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  `,

  updated: keyframes`
    0% {
      background: transparent;
    }

    50% {
      background: ${colors.getColor('primary')};
    }

    100% {
      background: transparent;
    }
  `,

  pulse: keyframes`
   0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  `
}

export default animations