import React, { useState, useRef, useEffect } from 'react'
import { Wrapper, Text, Icon, Input } from '../../../../../components'
import { useOutsideClick } from '../../../../../Hooks'
import { simpleCurrency } from '../../../../../assets/services/formatters'
import moment from 'moment'

const FormField = ({ fieldKey, name, value, type, isEditable, onEdit, ...props }) => {

    const fieldRef = useRef(null)
    useOutsideClick([fieldRef], () => setIsEditing(false))

    const [maskValue, setMaskValue] = useState(value)
    const [previousValue, setPreviousValue] = useState(value)

    const [isEditing, setIsEditing] = useState(false)

    const formatFieldValue = (value) => {

        if (type === 'date') {
            return moment(value).format('DD/MM/YYYY')

        } else if (type === 'currency') {
            return simpleCurrency(value)
        }

        return value
    }

    useEffect(() => {
        if (!isEditing && maskValue !== previousValue) {
            setPreviousValue(maskValue)
            onEdit(fieldKey, maskValue)
        }
    }, [isEditing])

    return (
        <Wrapper ref={fieldRef} flexbox width='100%' border='bottom' padding='15px 0 10px 0'>

            <Text opacity='0.5' width='30%'>{name}</Text>

            <Wrapper flexbox width='70%' justify='space-between' align='center'>

                {
                    isEditing ? (
                        <Input
                            value={maskValue}
                            onChange={(event) => setMaskValue(event.target.value)}
                            type={type}
                        />
                    ) : <Text>{formatFieldValue(maskValue)}</Text>
                }

                {
                    isEditable && (
                        <Wrapper flexbox onClick={() => setIsEditing(prevState => !prevState)}>
                            <Icon name={isEditing ? 'done' : 'edit'} size={isEditing ? 25 : 15} fill={isEditing ? 'success' : 'white'} opacity='0.5' />
                        </Wrapper>
                    )
                }

            </Wrapper>

        </Wrapper>
    )
}

export default FormField