import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FetchModal } from '../../App/components';
import EventBus from '../EventBus';

const FetchModalManager = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isLoadingBarOk, setIsLoadingBarOk] = useState(true);

    useEffect(() => {
        const show = () => {
            setIsVisible(true);
            setProgress(0);
        };
        
        const hide = () => {
            setIsVisible(false);
            setProgress(0);
            setIsLoadingBarOk(true)
        };

        const updateProgress = (event) => {
            setIsLoadingBarOk(event?.isApiOk)
            setProgress(event.detail);
        };

        EventBus.on('show-fetch-modal', show);
        EventBus.on('hide-fetch-modal', hide);

        EventBus.on('update-fetch-progress', updateProgress);

        return () => {
            EventBus.off('show-fetch-modal', show);
            EventBus.off('hide-fetch-modal', hide);

            EventBus.off('set-fetch-progress-modal', setProgress);
        };
    }, []);

    return isVisible ? ReactDOM.createPortal(<FetchModal progress={progress} isLoadingBarOk={isLoadingBarOk} />, document.getElementById('root')) : null;
};

export default FetchModalManager;
