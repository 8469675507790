import { keys } from 'ramda'
import withStyles from '../../assets/services/styles'

const base = `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  max-height: 75px;
  max-width: 75px;
`

// Sizes
const sizes = {
  small: `
    height: 15px;
    width: 15px;
  `,
  normal: `
    height: 30px;
    width: 30px;
  `,
  large: `
    height: 40px;
    width: 40px;
  `,
  huge: `
    height: 50px;
    width: 50px;
  `,
}

// Options
const options = {
  size: sizes,
}

export const props = {
  sizes: keys(sizes),
}

export default withStyles(base, options)
