import { useEffect, useState } from 'react';
import useImageCheck from '../../../Hooks/useImageCheck';

const CheckedImage = ({ width, height, src, defaultSrc, ...props }) => {
    const [imageSrc, setImageSrc] = useState(src);

    useImageCheck(imageSrc, () => setImageSrc(defaultSrc))

    useEffect(() => {
        if (!src) return

        setImageSrc(src)
    }, [src])

    return (
        <img
            style={{ height, width }}
            src={imageSrc}
            {...props}
        />
    );
};

export default CheckedImage;