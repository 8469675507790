import React, { useEffect, useState } from 'react'
import { Text, Wrapper } from '../../../components'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'


const Navlink = styled(Wrapper).attrs(props => ({
    style: {
        backgroundColor: props.active ? '#383838' : 'transparent',
        '&:before': {
            height: props.active ? '5px' : '0px'
        }
    }
}))`
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
        background-color: #383838;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ${props => props.active ? '5px' : '0px'};
        background: #1dc077;
        transition: height 0.1s ease-in-out;
    }

    &:hover:before {
        height: 5px;
    }
`

const propTypes = {

    leftControl: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(['date', false]), // string only to date control
            range: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]), // number only to date control
            views: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                display: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]).isRequired,
            }))]),
            selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired
        })]),

    viewControl: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            views: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                display: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]).isRequired,
            }))]),
            selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired
        })]),

    handleChangeLeftControl: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

    handleChangeView: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

}

const defaultProps = {
    leftControl: false,
    viewControl: false,
}

function createDatesRange(range) {
    let dates = []

    for (let i = 0; i < range; i++) {
        const date = {
            year: moment().subtract(i, 'month').format('YYYY'),
            month: moment().subtract(i, 'month').format('MM'),
            date: `${moment().subtract(i, 'month').format('MMM')} ${moment().subtract(i, 'month').format('YY')}`
        }
        dates.push(date)
    }

    return dates.reverse()
}

const PageControl = ({ children, leftControl, viewControl, handleChangeLeftControl, handleChangeView, ...props }) => {

    const [selectedLeftControl, setSelectedLeftControl] = useState(leftControl?.selectedValue)
    const [selectedViewControl, setSelectedViewControl] = useState(viewControl?.selectedValue)

    function getDatesRange() {
        const datesRange = createDatesRange(leftControl?.range || 6)
        return datesRange
    }

    useEffect(() => {
        setSelectedLeftControl(leftControl?.selectedValue)
        setSelectedViewControl(viewControl?.selectedValue)
    }, [leftControl, viewControl])

    return (
        <Wrapper flexbox column corner='default' bgColor="background.04">

            <Wrapper flexbox justify="space-between" align="center" border={(leftControl || viewControl) && children && "bottom"}>

                <Wrapper flexbox align="start">
                    {
                        leftControl?.type === 'date' ? getDatesRange().map((date, index) => {
                            return (
                                <Navlink
                                    key={index}
                                    active={selectedLeftControl.year === date.year && selectedLeftControl.month === date.month}
                                    onClick={() => { setSelectedLeftControl(date); handleChangeLeftControl && handleChangeLeftControl(date); }}>
                                    <Text fontSize="tiny">{date.date.toUpperCase()}</Text>
                                </Navlink>
                            )
                        }) :
                            <Wrapper flexbox align="start">
                                {
                                    (leftControl && selectedLeftControl) && leftControl?.views?.map((view, index) => {
                                        return (
                                            <Navlink key={index} active={selectedLeftControl === view?.key}
                                                onClick={() => { setSelectedLeftControl(view.key); handleChangeLeftControl && handleChangeLeftControl(view) }}>
                                                <Text fontSize="tiny">{view?.display}</Text>
                                            </Navlink>
                                        )
                                    })
                                }
                            </Wrapper>
                    }
                </Wrapper>

                <Wrapper flexbox align="end">
                    {
                        (viewControl && selectedViewControl) && viewControl?.views?.map((view, index) => {
                            return (
                                <Navlink key={index} active={selectedViewControl === view?.key} onClick={() => { setSelectedViewControl(view.key); handleChangeView(view); }}>
                                    <Text fontSize="tiny">{view?.display}</Text>
                                </Navlink>
                            )
                        })
                    }
                </Wrapper>

            </Wrapper>

            <Wrapper padding={children && "5px"}>
                {children}
            </Wrapper>

        </Wrapper>
    )
}

PageControl.propTypes = propTypes
PageControl.defaultProps = defaultProps

export default PageControl