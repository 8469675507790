import { keys } from 'ramda'

import { bg, corner, } from '../../assets/theme/by'
import withStyles from '../../assets/services/styles'

const base = `
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

const variants = {
    card: `background-image: linear-gradient(120deg, #1E1E1E 65%, #232323 35%);`
}

const options = {
    ...bg,
    ...corner,

    variant: variants,
}

export const props = {
    variants: keys(variants),
}

export default withStyles(base, options)