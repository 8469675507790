import React, { useContext, useEffect, useState, useRef } from 'react';
import { Wrapper, Text } from '../../../components';
import styled, { keyframes } from 'styled-components';
import { BASE_URL, DEFAULT_IMAGES, TEAM_IMAGES, USER_IMAGES } from '../../../Enviroment';
import useImageCheck from '../../../Hooks/useImageCheck';
import { colors } from '../../../assets/theme';
import Loading from '../Loading';
import { AppContext } from '../../../Context';
import { ProfileBadge, UserSectorProfile } from './Components';
import { useOutsideClick } from '../../../Hooks';
import Portal from '../../../components/Portal';

const slideInFromBottom = keyframes`
  0% {
    height: 0;
    width: 0;
  }
  100% {
    height: 25rem;
    width: 22rem;
  }
`;

const StyledWrapper = styled(Wrapper).attrs((props) => ({
    style: {
        width: '22rem',
        height: 'fit-content',
        maxHeight: '25rem',
        display: 'flex',
        position: 'absolute',
        zIndex: 10,
        transition: 'width 0.3s ease-in-out, height 0.5s ease-in-out',
        boxShadow: props.isPartner
            ? '1px 1px 3px 1px rgba(242, 217, 155, 0.6)'
            : props.userSector === 1
            ? '1px 1px 3px 1px #60BFFE33'
            : '1px 2px 6px 3px #17171780',
        border: props.isPartner ? '1px solid rgba(242, 217, 155)' : props.userSector === 1 ? '1px transparent' : '1px solid transparent',
        background: props.userSector === 1 ? 'linear-gradient(to bottom right, #60BFFE, #D21AB0)' : '#17171780',
        justify: props.isPartner ? 'space-between' : 'center',
    },
}))`
    animation: ${slideInFromBottom} 0.3s ease-in-out backwards;
`;

const UserImageWrapper = styled(Wrapper)`
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 5px solid #1e1e1e;
    position: absolute;
    left: 1rem;
    top: 1rem;
`;

const Tag = styled(Wrapper)`
    height: 1.2rem;
    padding: 6px;
    border-radius: 5px;
`;

const ProfileModal = ({ userId, triggerComponent }) => {
    const { globalContext } = useContext(AppContext);

    const requestUserInfo = globalContext?.user;
    const userAccessList = globalContext?.accessList;
    const accessListIds = userAccessList.allList.map((item) => item.id);

    const profileModalRef = useRef(null);
    const triggerRef = useRef(null);

    useOutsideClick([profileModalRef], () => setIsOpen(false));

    const [userData, setUserData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const sectorsWithTeams = [10, 33, 34];
    const isUserWithTeam = sectorsWithTeams.includes(userData?.sector);
    const userSectorColor = !isUserWithTeam ? `home.${userData?.sector}` : `teams.${userData?.bigTeamId}`;

    const [teamImage, setTeamImage] = useState();
    const [userImage, setUserImage] = useState();

    useImageCheck(`${TEAM_IMAGES}/${!isUserWithTeam ? `sector_team_${userData?.sector}` : userData?.bigTeamId}.png`, () =>
        setTeamImage(`${DEFAULT_IMAGES}/team.png`)
    );

    useImageCheck(`${USER_IMAGES}/${userData?.assPhoto}`, () => setUserImage(`${DEFAULT_IMAGES}/user.png`));

    async function getUsersInfo(user) {
        setIsLoading(true);
        try {
            const url = new URL(`${BASE_URL}/hub/profile/profile-modal`);
            const queryParams = { id: user };
            url.search = new URLSearchParams(queryParams).toString();

            const response = await fetch(url.toString());

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setUserData(data);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    function getMainColor(type) {
        return colors.getColor(!isUserWithTeam ? `${userSectorColor}.${type}` : `${userSectorColor}.${type}`);
    }

    useEffect(() => {
        if (isOpen) {
            getUsersInfo(userId);
        }
    }, [isOpen, userId]);

    return (
        <Wrapper
            flexbox
            position='relative'
            onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
            }}
            width='fit-content'
            ref={triggerRef}
        >
            {triggerComponent}

            {isOpen && (
                <Portal triggerRef={triggerRef} defaultWidth={348} isOpen={isOpen} onClose={() => setIsOpen(false)} customTop={10}>
                    <StyledWrapper
                        ref={profileModalRef}
                        bgColor='background.01'
                        isPartner={userData?.isPartner}
                        userSector={userData?.sector}
                        padding='1px'
                        column
                        corner='bowed'
                    >
                        {!isLoading ? (
                            <Wrapper zIndex={10} flexbox column bgColor='background.03'>
                                <Wrapper position='relative' flexbox width='100%'>
                                    <Wrapper
                                        flexbox
                                        position='relative'
                                        width='100%'
                                        height='2.3rem'
                                        corner='6px 6px 0 0'
                                        opacity='0.8'
                                        align='center'
                                        style={{ overflow: 'hidden' }}
                                        bgImage={`linear-gradient(to right, ${getMainColor('background')}, transparent)`}
                                    >
                                        <img src={teamImage} width='150px' alt='' />
                                    </Wrapper>
                                    <UserImageWrapper bgImage={`url(${userImage})`} />
                                </Wrapper>

                                <Wrapper flexbox column height='1.2rem' position='absolute' gap='4px' fontSize='tiny' top='3rem' left='29%'>
                                    <Text>{userData?.nameDisplay}</Text>
                                    <Wrapper flexbox gap>
                                        <Tag flexbox center bgColor={`${getMainColor('background')}40`}>
                                            <Text fontSize='tiny' color={getMainColor('tag')}>
                                                {isUserWithTeam ? userData?.squadName : userData?.sectorName}
                                            </Text>
                                        </Tag>
                                        <Tag flexbox center bgColor='#23BF4D40'>
                                            <Text color='success.10' fontSize='tiny'>
                                                {userData?.filial}
                                            </Text>
                                        </Tag>
                                    </Wrapper>
                                </Wrapper>

                                <UserSectorProfile requestUserInfo={requestUserInfo} user={userData} accessListIds={accessListIds} />

                                <ProfileBadge reference={userData?.isPartner} />
                            </Wrapper>
                        ) : (
                            <Loading type='box' height='19rem' padding width='100%' />
                        )}
                    </StyledWrapper>
                </Portal>
            )}
        </Wrapper>
    );
};

export default ProfileModal;
