const options = {
  opacity: ({ opacity }) => `
    opacity: ${opacity};
  `,
  
  opacityHover: ({ opacityHover }) => `
    &:hover {
      opacity:  ${opacityHover};
    }
  `,
}

export default options
