import React, { useState, useEffect } from "react";
import { Wrapper, Text, Icon, DataTable } from "../../../../../components";
import ProgressCircle from "../../../ProgressCircle";
import { simpleCurrency } from "../../../../../assets/services/formatters";
import { colors } from "../../../../../assets/theme";

const ExpandablePanel = ({ data, customerNet, ...props }) => {

    const [isOpenFatherPanel, setIsOpenFatherPanel] = useState(false)
    const [childrensPanelsControl, setChildrensPanelsControl] = useState({})

    const handleChildrensPanel = (key) => {
        const keyControl = childrensPanelsControl.hasOwnProperty(key) ? childrensPanelsControl[key] : false

        setChildrensPanelsControl(prevState => {
            return { ...prevState, [key]: !keyControl }
        })
    }

    const assetsColumns = [
        { key: 'ativo', header: 'Ativo', colSpan: 2 },
        { key: 'quantidade', header: 'Quantidade' },
        { key: 'net', header: 'Net', format: (obj, key) => { return simpleCurrency(obj[key]) } },
    ]

    useEffect(() => {
        setChildrensPanelsControl({})
    }, [isOpenFatherPanel])

    return (
        <Wrapper flexbox column {...props}>

            <Wrapper flexbox align='center' bgColor='background.05' padding='0.5rem 1rem' justify='space-between' bgHover='background.06'
                transition='background-color 0.2s ease-in-out' onClick={() => setIsOpenFatherPanel(!isOpenFatherPanel)} border='bottom'>

                <Wrapper flexbox align='center' width='70%'>

                    <Wrapper flexbox center width='15%'>
                        <ProgressCircle
                            currentValue={data.net}
                            totalValue={customerNet}
                            size='small'
                            color={data?.color}
                        />
                    </Wrapper>

                    <Wrapper flexbox column>
                        <Text fontSize='tiny' opacity='0.7'>Categoria</Text>
                        <Text>{data?.name}</Text>
                    </Wrapper>

                </Wrapper>


                <Wrapper flexbox column width='10%'>
                    <Text fontSize='tiny' opacity='0.7'>Posição</Text>
                    <Text fontSize='small' strong>{simpleCurrency(data?.net)}</Text>
                </Wrapper>

                <Icon name={isOpenFatherPanel ? 'expand_less' : 'expand_more'} fill='white' size={30} />


            </Wrapper>

            {
                isOpenFatherPanel && (
                    data?.assets.map((macroAsset, index) => {
                        const isOpen = childrensPanelsControl?.[macroAsset.label]
                        return (
                            macroAsset?.net > 0 && (
                                <Wrapper key={index} flexbox column bgColor={isOpen ? 'background.08' : 'background.06'} border='bottom' transition='border 0.2s ease-in-out'
                                    style={{ borderLeft: `${isOpen ? '10px' : '5px'} solid ${data?.color}` }}>

                                    <Wrapper flexbox align='center' padding='0.5rem 1rem' justify='space-between' bgHover='background.08'
                                        onClick={() => handleChildrensPanel(macroAsset?.label)} transition='background-color 0.2s ease-in-out'>

                                        <Wrapper flexbox align='center' width='70%'>

                                            <Wrapper flexbox center width='15%'>
                                                <ProgressCircle
                                                    currentValue={macroAsset?.net}
                                                    totalValue={customerNet}
                                                    size='tiny'
                                                    color={macroAsset?.color}
                                                />
                                            </Wrapper>

                                            <Text fontSize='small' medium>{macroAsset?.name}</Text>

                                        </Wrapper>


                                        <Wrapper flexbox column width='10%'>
                                            <Text fontSize='small' strong>{simpleCurrency(macroAsset?.net)}</Text>
                                        </Wrapper>

                                        <Icon name={isOpen ? 'expand_less' : 'expand_more'} fill='white' size={30} />

                                    </Wrapper>

                                    {
                                        childrensPanelsControl?.[macroAsset.label] && (
                                            macroAsset?.assets.map((asset, idx) => {
                                                return (
                                                    <Wrapper key={idx} flexbox column padding gap>

                                                        <Wrapper flexbox gap='8px' fontSize='small'>
                                                            <Text>{asset?.name}</Text>
                                                            <Text opacity='0.8'>({simpleCurrency(asset?.net)})</Text>
                                                        </Wrapper>

                                                        <DataTable
                                                            columns={assetsColumns}
                                                            data={asset?.assets}
                                                            rowProps={{ fontSize: 'tiny', lineHeight: '30px' }}
                                                            headerProps={{ fontSize: 'tiny', lineHeight: '30px', background: colors.getColor('background.06') }}
                                                            bgColor='background.04'
                                                        />

                                                    </Wrapper>
                                                )
                                            })
                                        )
                                    }

                                </Wrapper>
                            )
                        )
                    })
                )
            }

        </Wrapper>
    )
}

export default ExpandablePanel