import styled from 'styled-components'
import styledBy from 'styled-by'
import { keys, omit } from 'ramda'
import PropTypes from 'prop-types'

const mapPropTypes = (prop, options) => ({
  string: { [prop]: PropTypes.bool },
  object: {
    [prop]: options.true
      ? PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.oneOf(keys(omit(['_'], options))),
        ])
      : PropTypes.oneOf(keys(omit(['_'], options))),
  },
  function: {},
})

export const extractPropTypes = options =>
  keys(options).reduce(
    (memo, prop) => ({
      ...memo,
      ...mapPropTypes(prop, options[prop])[typeof options[prop]],
    }),
    {}
  )

export const withStyles = style => component =>
  styled(component)`
    ${style}
  `

export const withOptions = options => component =>
  withStyles(styledBy(options, component.defaultProps))(component)

export default (style, options) => component =>
  styled(component)`
    ${style} ${options ? styledBy(options, component.defaultProps) : ''}
  `
