import moment from 'moment'
import momentTimezone from 'moment-timezone'

import timezones from './timezone'

const humanizeDuration = duration => {
  if (duration.days()) {
    return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`
  }

  if (duration.hours()) {
    return `${duration.hours()}h ${duration.minutes()}m`
  }

  if (duration.minutes()) {
    return `${duration.minutes()}m ${duration.seconds()}s`
  }

  return `${duration.seconds()}s`
}

const getDuration = (begin, end) => {
  const duration = moment.duration(moment(end).diff(moment(begin)))

  if (duration.days()) {
    return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`
  }

  if (duration.hours()) {
    return `${duration.hours()}h ${duration.minutes()}m`
  }

  if (duration.minutes()) {
    return `${duration.minutes()}m ${duration.seconds()}s`
  }

  return `${duration.seconds()}s`
}

export { moment, momentTimezone, timezones, humanizeDuration, getDuration }

export default momentTimezone
