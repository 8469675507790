import withStyles, { extractPropTypes } from '../../assets/services/styles';
import { colors } from '../../assets/theme';

import { bg, border, attached, padding, gap, font, flexbox, margin, radius, corner, opacity } from '../../assets/theme/by';

const base = `
  overflow: initial;
  min-width: 0px;
`;

const options = {
  ...bg,
  ...border,
  ...corner,
  ...attached,
  ...padding,
  ...gap,
  ...margin,
  ...font,
  ...flexbox,
  ...radius,
  ...opacity,

  position: ({ position }) => `position: ${position};`,
  transition: ({ transition }) => `transition: ${transition};`,
  transform: ({ transform }) => `transition: ${transform};`,
  rotate: ({ rotate }) => `transform: rotate(${rotate}deg);`,

  height: ({ height }) => `height: ${height};`,
  width: ({ width }) => `width: ${width};`,
  minHeight: ({ minHeight }) => `min-height: ${minHeight};`,
  maxHeight: ({ maxHeight }) => `max-height: ${maxHeight};`,
  minWidth: ({ minWidth }) => `min-width: ${minWidth};`,
  maxWidth: ({ maxWidth }) => `max-width: ${maxWidth};`,

  top: ({ top }) => `top: ${top};`,
  right: ({ right }) => `right: ${right};`,
  bottom: ({ bottom }) => `bottom: ${bottom};`,
  left: ({ left }) => `left: ${left};`,

  opacity: ({ opacity }) => `opacity: ${opacity};`,
  boxShadow: ({ boxShadow }) => `box-shadow: ${boxShadow};`,

  zIndex: ({ zIndex }) => `z-index: ${zIndex};`,
  content: ({ content }) => `align-content: ${content};`,
  textAlign: ({ textAlign }) => `text-align: ${textAlign}`,
  cursor: ({ cursor }) => `cursor: ${cursor};`,

  // opacityHover: ({ opacityHover }) => `
  //     &:hover {
  //       opacity: ${opacityHover};
  //     }
  //   `,

  scroll: {
    true: `
      overflow: auto;

      @media print {
        overflow: visible;
      }
    `,

    hidden: `
      overflow: hidden;
    `,

    horizontal: `
      overflow: auto;
      flex-shrink: none;
    `,
  },

  full: {
    true: `height: 100vh;`,
    '%': `height: 100%;`,
  },

  isLoading: `
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  `,

  disabled: `
    background-color: ${colors['disabled'].base};
    opacity: 0.4;
    cursor: not-allowed;
  `,

  onClick: `
    cursor: pointer;
  `,

  container: `
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
  `,

  borderHover: `
    border: 1px solid transparent;

    &:hover {
      border: 1px solid white;
    }
 `,

  hover: `
  &:hover {
    opacity: 0.5;
  }
  `,

  truncate: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

export const optionsPropTypes = extractPropTypes(options);
export default withStyles(base, options);
