import { keys } from 'ramda'

import withStyles from '../../assets/services/styles'
import { colors } from '../../assets/theme'

import {
  margin,
} from '../../assets/theme/by'

const styles = `
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  border-radius: 3px;
  vertical-align: baseline;
  color: rgba(0,0,0,.6);
  padding: .78571429em 1.5em;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
  transition: opacity 0.1s ease-in-out, background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease, border-color .1s ease-in-out;
  position: relative;
  width: fit-content;

  & ~ Button {
    margin-left: 5px;
  }

  @media print {
    display: none;
  }
`

// Sizes
const sizes = {
  mini: `
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  `,
  small: `
    font-size: 0.8rem;
    padding: 0.375rem 0.75rem;
  `,
  normal: `
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  `,
  large: `
    font-size: 0.8rem;
    padding: 0.75rem 1.5rem;
  `,
  huge: `
    font-size: 0.875rem;
    padding: 1rem 2rem;
  `,
};

// Corners
const corners = {
  square: `border-radius: none;`,
  rounded: `border-radius: 50px;`,
  circle: `border-radius: 1.5rem;`,
}

// Themes
const kinds = {
  default: ({ color, disabled }) => `
    background: ${disabled ? colors['disabled'].base : colors.getColor(color)};
    color: ${disabled ? colors['disabled'].contrast : 'black'};
    border: none;

    &: hover {
      background: ${colors.getColor(color)}80; 
    }
  `,
  outline: ({ color, disabled }) => `
    background: transparent;
    color: ${colors[disabled ? 'disabled' : color].base};
    border: 1px solid ${colors[disabled ? 'disabled' : color].base};

    &: hover {
      border-color: ${colors.getColor(color)}80; 
    }
  `,
  clean: ({ color, disabled }) => `
    background: transparent;
    color: ${disabled ? colors[disabled ? 'disabled' : color].dark : color};
    border: none;
  `,
}

const opacityHover = ({ opacityHover }) => `
&:hover {
  opacity: ${opacityHover};
}
`

// Fluid
const fluid = `width: 100%;`

// Flex
const flex = `flex: 1;`

// isLoading
const isLoading = `
  padding-top: 0;
  paddingBottom:0;
`

const disabled = `
  cursor: not-allowed;
`

const height = ({ height }) => `
  height: ${height};
`

const maxHeight = ({ maxHeight }) => `
  max-height: ${maxHeight};
`

const transition = ({ transition }) => `transition: ${transition};`

// Options
const options = {
  size: sizes,
  corner: corners,
  kind: kinds,
  fluid,
  isLoading,
  flex,
  disabled,
  transition,
  maxHeight,
  height,
  opacityHover,
  ...margin,
}

export const props = {
  sizes: keys(sizes),
  corners: keys(corners),
  colors: ['default', 'success', 'danger', 'primary', 'info', 'warning'],
  kinds: keys(kinds),
}

export default withStyles(styles, options)
