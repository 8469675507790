import React from 'react';
import { Tooltip, Wrapper } from '../../../../components';

const Bullet = ({ id, bullet, ...props }) => {

    return (
        <>
            {
                bullet.tooltip ? (
                    <Tooltip description={bullet.tooltip()}>

                        <Wrapper
                            flexbox
                            className={`point_${id}`}
                            height='15px'
                            width='15px'
                            minHeight='15px'
                            minWidth='15px'
                            corner='circle'
                            bgColor='gray.dark'
                            position='relative'
                            style={{ transition: 'background-color ease-in-out 0.3s, width ease-in-out 0.3s, height ease-in-out 0.3s' }}
                            center
                        >
                            {
                                bullet?.label && (
                                    <Wrapper position='absolute' fontSize='small' style={{ left: '50%', top: '150%', transform: 'translateX(-50%)' }}>{bullet.label}</Wrapper>
                                )
                            }
                            <Wrapper height='8px' width='8px' bgColor='white' corner='circle'></Wrapper>
                        </Wrapper>
                    </Tooltip>
                ) : (
                    <Wrapper
                        flexbox
                        className={`point_${id}`}
                        height='15px'
                        width='15px'
                        corner='circle'
                        bgColor='gray.dark'
                        position='relative'
                        style={{ transition: 'background-color ease-in-out 0.3s, width ease-in-out 0.3s, height ease-in-out 0.3s' }}
                        center
                    >
                        {
                            bullet?.label && (
                                <Wrapper position='absolute' fontSize='small' style={{ left: '50%', top: '150%', transform: 'translateX(-50%)' }}>{bullet.label}</Wrapper>
                            )
                        }
                        <Wrapper height='8px' width='8px' bgColor='white' corner='circle'></Wrapper>
                    </Wrapper>
                )
            }

        </>
    )
}

export default Bullet