import React from 'react';
import Loading from '../../../Loading';
import { DataTable, Wrapper, Text } from '../../../../../components';

const ActivitiesHistory = ({ customer, viewInfo, isLoading }) => {

    const columns = [
        { key: 'date', header: 'Data', format: 'date' },
        { key: 'authorName', header: 'Autor' },
        { key: 'activityType', header: 'Tipo Atividade' },
        { key: 'activityDetail', header: 'Detalhamento Atividade' },
    ];

    const tables = [
        { name: 'Atividades - Assessor', data: viewInfo?.advisor || [] },
        { name: 'Atividades - Especialista', data: viewInfo?.specialist || [] }
    ]

    return (
        <Wrapper flexbox>
            {
                !isLoading ? (
                    <Wrapper flexbox column padding='2rem' gap='3rem'>

                        {
                            tables.map((table, index) => {
                                return (
                                    <Wrapper key={index} flexbox column gap='big'>

                                        <Text medium>{table.name}</Text>

                                        <Wrapper fontSize='tiny'>
                                            <DataTable
                                                bgColor='background.03'
                                                columns={columns}
                                                data={table.data}
                                                rowProps={{ lineHeight: '2.5rem' }}
                                                headerProps={{ lineHeight: '2rem' }}
                                                pagination={10}
                                            />
                                        </Wrapper>

                                    </Wrapper>
                                )
                            })
                        }

                    </Wrapper>
                ) : (
                    <Loading type='table' />
                )
            }
        </Wrapper>
    );
};

export default ActivitiesHistory;
