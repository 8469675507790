import React, { useEffect, useState } from 'react';
import { Wrapper, Modal, Input, Text, Select, Button } from '../../../../../../../components';
import { colors } from '../../../../../../../assets/theme';
import { AWS_API_TOKEN, BASE_URL, LAMBDA_URL } from '../../../../../../../Enviroment';
import { postFallback, putFallback } from '../../../../../../../assets/services/apis/api';
import moment from 'moment';

const CreateActivityModal = ({ requestUser, selectedUser, selectedCustomer, closeModal, handleMaskUpdates, sectorsColumns, onUpdateRow }) => {

    const requiredFields = ['date', 'description', 'type']

    const activitiesOptions = [
        {
            key: '1', name: 'Ligação',
            options: [
                { key: '1_1', name: 'Pós Venda', typeId: 1, detailId: 19 },
                { key: '1_2', name: 'Captação', typeId: 1, detailId: 18 },
                { key: '1_3', name: 'Alocação', typeId: 1, detailId: 17 },
                { key: '1_4', name: 'Relacionamento', typeId: 1, detailId: 14 },
            ]
        },
        {
            key: '2', name: 'Reunião',
            options: [
                { key: '2_1', name: 'Pós Venda', typeId: 2, detailId: 19 },
                { key: '2_2', name: 'Captação', typeId: 2, detailId: 18 },
                { key: '2_3', name: 'Alocação', typeId: 2, detailId: 17 },
                { key: '2_4', name: 'Relacionamento', typeId: 2, detailId: 14 },
            ]
        },
        {
            key: '3', name: 'WhatsApp',
            options: [
                { key: '3_1', name: 'Pós Venda', typeId: 3, detailId: 19 },
                { key: '3_2', name: 'Captação', typeId: 3, detailId: 18 },
                { key: '3_3', name: 'Alocação', typeId: 3, detailId: 17 },
                { key: '3_4', name: 'Relacionamento', typeId: 3, detailId: 14 },
            ]
        },
        {
            key: '4', name: 'Operacional',
            options: [
                { key: '4_1', name: 'Operacional', typeId: 4, detailId: 20 },
            ]
        },
    ]

    const defaultFormController = { title: '', date: moment().format('YYYY-MM-DD'), description: '' }
    const [formController, setFormController] = useState(defaultFormController)

    async function createActivity() {
        const isCheckRequiredFields = requiredFields.every(field => formController?.[field])

        if (!isCheckRequiredFields) {
            return alert('Preencha todos os campos necessários')
        }

        const queryParams = { id: requestUser.id }

        const body = {
            creatorId: requestUser.id,
            authorId: selectedUser.id,
            dealId: selectedCustomer.dealId,
            customerCode: selectedCustomer.customerCode,
            ...formController
        }

        const url = new URL(`${BASE_URL}/commercial/customers/portfolio/create/activity`)
        url.search = new URLSearchParams(queryParams)

        const productKey = sectorsColumns?.[selectedUser.sectorId]?.key?.slice(0, -5);
        const newCustomerValue = ({ ...selectedCustomer, [productKey]: { ...selectedCustomer[productKey], introduced: true } })

        onUpdateRow(newCustomerValue)
        handleMaskUpdates(newCustomerValue)

        await postFallback(url, JSON.stringify(body), true, true).then(res => {

            if (res.ok) {
                sendActivityToPipedrive(res.data.id, body)
            } else {
                onUpdateRow(selectedCustomer)
                handleMaskUpdates(selectedCustomer)
            }

            closeModal();
        })
    }

    async function sendActivityToPipedrive(activityId, body) {
        const formattedNote = `[${selectedUser.name}]: ${body.description}`

        const pipeBody = {
            activityType: 'mesa_is',
            dealId: body.dealId,
            token: AWS_API_TOKEN,
            dueDate: moment(body.date).format('YYYY-MM-DD'),
            dueTime: moment(body.date).format('hh:mm'),
            pipeId: 0,
            note: formattedNote
        }

        putFallback(`${LAMBDA_URL}/create-activity`, JSON.stringify(pipeBody), false, 'only-warning').then(res => {
            const updateBody = {
                activityId,
                key: res.ok ? 'pipe_activity_id' : 'is_deleted',
                value: res.ok ? res.data.activityId : true
            }

            putFallback(`${BASE_URL}/commercial/customers/portfolio/update/activity`, JSON.stringify(updateBody), false, false)
        })
    }

    const handleFormController = (key, value) => {
        setFormController(prevState => { return { ...prevState, [key]: value } })
    }

    useEffect(() => {
        setFormController(defaultFormController)
    }, [selectedCustomer])

    return (
        <Modal open={selectedCustomer}>
            <Wrapper flexbox column bgColor='background.05' width='30vw' padding gap='huge'>

                <Text fontSize='large' strong>Marcar Atividade</Text>

                <Wrapper flexbox bgColor={`${colors.getColor('warning')}1A`} padding='0.7rem' corner='default'>
                    <Text fontSize='tiny' color='warning'><strong style={{ color: colors.getColor('warning') }}>Evite Erros:</strong> no momento,
                        atividades marcadas não podem ser editadas. Se acontecer, contate a área de BI.</Text>
                </Wrapper>

                <Wrapper flexbox column gap='large'>

                    <Input
                        label='Titulo'
                        value={formController.title}
                        placeholder='Dê um título para a atividade'
                        onChange={(e) => handleFormController('title', e.target.value)}
                        height='2.2rem'
                    />

                    <Wrapper flexbox gap width='100%'>

                        <Wrapper width='50%'>
                            <Select
                                label={{ display: 'Tipo' }}
                                isRequired
                                options={activitiesOptions}
                                selectedValue={formController?.type}
                                onChangeOption={(id, value) => handleFormController('type', value)}
                                height='2.2rem'
                            />
                        </Wrapper>

                        <Wrapper width='50%'>
                            <Input
                                label='Data'
                                isRequired
                                type='date'
                                value={formController.date}
                                onChange={(e) => handleFormController('date', e.target.value)}
                                height='2.2rem'
                            />
                        </Wrapper>

                    </Wrapper>

                    <Input
                        label='Descrição'
                        isRequired
                        type='textarea'
                        placeholder='Descreva brevemente a atividade'
                        value={formController.description}
                        onChange={(e) => handleFormController('description', e.target.value)}
                        height='5rem'
                    />

                </Wrapper>

                <Wrapper flexbox justify='end'>

                    <Button kind='clean' onClick={() => closeModal()}>
                        <Text>Cancelar</Text>
                    </Button>

                    <Button color='success' onClick={() => createActivity()}>
                        <Text>Marcar Atividade</Text>
                    </Button>

                </Wrapper>

            </Wrapper>
        </Modal>
    )
}

export default CreateActivityModal