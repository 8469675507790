import React, { useState, useRef, useEffect } from 'react';
import { Wrapper, Text, Icon, Box } from '../../../../../../../../components';
import styled, { keyframes, css } from 'styled-components';
import { colors } from '../../../../../../../../assets/theme';
import Portal from '../../../../../../../../components/Portal';

const slideIn = keyframes`
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

const HiddenWrapper = styled(Wrapper).attrs((props) => ({
    style: {
        opacity: props.isSelected ? 1 : 0,
        transform: props.isSelected ? 'translateX(0)' : 'translateX(-50%)',
        top: '30%',
    },
}))`
    position: ${(props) => (props.isSelected ? 'static' : 'absolute')};
`;

const StyledWrapper = styled(Wrapper)`
    min-width: 3rem;
    width: ${(props) => (props.isSelected ? '6rem' : '3rem')};

    &:hover {
        width: ${(props) => props.isAbleToUpdate && '6rem'};

        ${HiddenWrapper} {
            position: ${(props) => props.isAbleToUpdate && 'static'};
            animation: ${(props) =>
                props.isSelected || !props.isAbleToUpdate
                    ? css``
                    : css`
                          ${slideIn} 0.2s forwards
                      `};
        }
    }

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
`;

const PillWrapper = ({ children, isSelected, onClose, isAbleToUpdate, handleDropdownController, tagColor, tagText, tagKey, ...props }) => {
    const wrapperRef = useRef(null);

    return (
        <StyledWrapper
            ref={wrapperRef}
            position='relative'
            flexbox
            bgColor={`${colors.getColor(tagColor)}33`}
            padding='0 0.2rem'
            center
            isAbleToUpdate={isAbleToUpdate}
            isSelected={isSelected}
            {...props}
        >
            <Wrapper position='relative' flexbox onClick={() => isAbleToUpdate && handleDropdownController(tagKey)} width='100%'>
                <Wrapper flexbox width='100%' center style={{ overflowX: 'hidden' }}>
                    <Text color={tagColor}>{tagText}</Text>
                </Wrapper>

                <HiddenWrapper flexbox center isSelected={isSelected}>
                    <Icon name='expand_more' fill={tagColor} size={20} />
                </HiddenWrapper>
            </Wrapper>

            {isSelected && (
                <Portal triggerRef={wrapperRef} isOpen={isSelected} customTop={3} onClose={onClose}>
                    <Box position='absolute' width='fit-content' bgColor='background.07' minWidth='8rem' zIndex='15'>
                        {children}
                    </Box>
                </Portal>
            )}
        </StyledWrapper>
    );
};

export default PillWrapper;
