import React from 'react'
import PropTypes from 'prop-types'
import { pick } from 'ramda'

import { colors } from '../../../assets/theme'
import { bearing, opacity, margin } from '../../../assets/theme/by'
import { Text } from '../../../components';

import { withOptions } from '../../../assets/services/styles'
import map from '../map.json'

const propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  styles: PropTypes.object,
}

const defaultProps = {
  size: 25,
  styles: {},
}

const options = {
  ...bearing,
  ...opacity,
  ...margin,
  cursor: ({ cursor }) => `
    cursor: ${cursor}
  `,
  size: `
    overflow: inherit !important;
  `,
  transition: ({ transition }) => `
    transition: all ${transition};
  `,
}

const Standalone = ({ name, size, fill, ...props }) => {
  props.className += ' material-symbols-sharp'
  // if (!map[name]) {
  //   return (<Text {...props} color={fill} fontSize={size}>{name}</Text>)
  // }

  const pathsArr = map[name].paths.split('fill')
  const polygonsArr = map[name].polygons.split('fill')
  const circlesArr = map[name].circles
    ? map[name].circles.split(';').filter(circle => !!circle)
    : []

  return (
    <svg
      {...pick(['onClick', 'className', 'style'], props)}
      fill={fill ? colors.getColor(fill) : null}
      width={size}
      height={size}
      viewBox={map[name] ? map[name].viewBox : '0 0 50 50'}
    >
      <g>
        {circlesArr.map((circle, index) => (
          <circle
            key={`poly_${circle}-${index}`}
            cx={circle.split('_')[0]}
            cy={circle.split('_')[1]}
            r={circle.split('_')[2]}
          />
        ))}
        {polygonsArr.map((d, index) => (
          <polygon
            key={`poly_${d}-${index}`}
            points={d.replace('init', '').replace('end', '')}
            fill={d.includes('init') ? '#3C92CA' : null}
          />
        ))}
        {pathsArr.map((d, index) => (
          <path
            key={`${d}-${index}`}
            d={d.replace('init', '').replace('end', '')}
            fill={d.includes('init') ? '#3C92CA' : null}
          />
        ))}
      </g>
    </svg>
  )
}

Standalone.propTypes = propTypes
Standalone.defaultProps = defaultProps

export default withOptions(options)(Standalone)
