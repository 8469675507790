import React, { useEffect, useState, useMemo } from 'react';
import { Input, Modal, Wrapper, Button, Text, Icon } from '../../../../../../../components';

const LinkedAccountsModal = ({ selectedCustomer, closeModal, updateLinkedAccounts, customers }) => {

    const [inputValue, setInputValue] = useState('')
    const [linkedAccounts, setLinkedAccounts] = useState(selectedCustomer?.linkedAccounts || [])

    const filteredData = useMemo(() => {
        return customers.filter(customer => (customer.customerName.toUpperCase().includes(inputValue.toUpperCase()) ||
            String(customer.customerCode).includes(String(inputValue))) && !linkedAccounts.includes(Number(customer.customerCode)) &&
            Number(customer.customerCode) !== Number(selectedCustomer?.customerCode))
    }, [inputValue])

    const handleInputValue = (event) => {
        const value = event.target.value
        setInputValue(value);
    }

    function removeLinkedAccount(account) {
        const updatedData = linkedAccounts.filter(item => item !== account)
        setLinkedAccounts(updatedData)
    }

    useEffect(() => {
        setLinkedAccounts(selectedCustomer?.linkedAccounts || [])
    }, [selectedCustomer])

    return (
        <Modal open={selectedCustomer} title='Grupo Econômico' close={closeModal}>

            <Wrapper flexbox column bgColor='background.05' height='50vh' width='50vw' justify='space-between'>

                <Wrapper flexbox gap='big' padding>

                    <Wrapper position='relative' width='50%' height='fit-content'>

                        <Wrapper flexbox>
                            <Input
                                label='Pesquisar Contas'
                                icon='search'
                                value={inputValue}
                                onChange={handleInputValue}
                            />
                        </Wrapper>

                        {
                            inputValue.length > 0 && (
                                <Wrapper position='absolute' top='120%' flexbox column height='fit-content' maxHeight='10rem'
                                    bgColor='background.07' style={{ overflowY: 'scroll' }} zIndex={50}>
                                    {
                                        filteredData.map((customer, index) => {
                                            return (
                                                <Wrapper key={index} flexbox fontSize='tiny' padding='0.2rem 0.5rem' bgHover='background.08'
                                                    onClick={() => { setLinkedAccounts(prevstate => [...prevstate, customer.customerCode]); setInputValue('') }}>
                                                    <Text>{`${customer.customerName} - ${customer.customerCode}`}</Text>
                                                </Wrapper>
                                            )
                                        })
                                    }
                                </Wrapper>
                            )
                        }
                    </Wrapper>

                    <Wrapper flexbox column width='50%' fontSize='small' gap>

                        <Text fontSize='tiny'>Grupo Econômico</Text>

                        <Wrapper flexbox column corner='default' bgColor='background.08' padding='0.5rem' gap>

                            {
                                linkedAccounts.map((account, index) => {
                                    const customer = customers.find(item => item.customerCode === account)

                                    return (
                                        <Wrapper key={index} flexbox padding='0.2rem 0.5rem' bgColor='background.10' justify='space-between' corner='default' align='center'>

                                            <Text>{`${customer?.customerName} - ${customer?.customerCode}`}</Text>

                                            <Wrapper flexbox center bgHover='background.10' onClick={() => removeLinkedAccount(account)} padding='0.1rem'>
                                                <Icon name='close' size={15} fill='white' />
                                            </Wrapper>

                                        </Wrapper>
                                    )
                                })
                            }

                        </Wrapper>

                    </Wrapper>

                </Wrapper>

                <Wrapper flexbox justify='end' padding='0.5rem' bgColor='background.07'>

                    <Button color='success' onClick={() => { updateLinkedAccounts(selectedCustomer.customerCode, linkedAccounts); closeModal() }}>
                        <Text>Confirmar</Text>
                    </Button>

                    <Button color='warning' onClick={() => closeModal()}>
                        <Text>Cancelar</Text>
                    </Button>

                </Wrapper>

            </Wrapper>

        </Modal>
    )
}

export default LinkedAccountsModal