import React from "react";
import styled, { keyframes } from "styled-components";


const shimmer = keyframes`
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
`;

const ShimmerDiv = styled.div`
  width: ${props => props.width && props.width};
  height: ${props => props.height && props.height};
  background: linear-gradient(90deg, #252525 25%, #333333 50%, #252525 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s ease infinite;
  border-radius: 3px;
`;

const BoxSkeleton = ({ height, width, ...props }) => {

  return (
    <ShimmerDiv
      height={height}
      width={width}
      {...props}
    />
  )
}

export default BoxSkeleton