const options = {
  bearing: ({ bearing }) => `
    transform: rotate(${bearing}deg);
  `,
  rotateY: ({ rotateY }) => `
    transform: rotateY(${rotateY}deg);
  `,
}

export default options
