import React, { useEffect, useState } from 'react';
import { Wrapper, Text, Icon, Input } from '../../../../../components';
import { colors } from '../../../../../assets/theme';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
    from {
        transform: translateY(20%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const slideOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(20%);
        opacity: 0;
    }
`;

const ReplyMessageWrapper = styled(Wrapper).attrs(props => ({
    style: {
        backgroundColor: colors.getColor('background.03'),
    }
}))`
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
    animation: ${props => props.isOpen ? slideIn : slideOut} 0.2s ease forwards;
`

const ChatInput = ({ requestUserInfo, selectedReplyMessage, cancelReplyMessage, onSendMessage }) => {

    const inputElement = document.getElementById('chat_comment')

    const [inputValue, setInputValue] = useState('')
    const [inputHeight, setInputHeight] = useState(2.2)

    async function sendMessage() {
        if (inputValue.length > 0) {
            const messageType = selectedReplyMessage ? 4 : 1

            const body = {
                authorId: requestUserInfo?.id,
                authorPhoto: requestUserInfo?.ass_photo,
                authorName: requestUserInfo?.name_display,
                comment: inputValue,
                lastUpdate: new Date(),
                typeId: messageType,
                replyId: selectedReplyMessage ? selectedReplyMessage?.id : null,
                replyInfo: selectedReplyMessage
            }

            setInputValue('')
            cancelReplyMessage()
            onSendMessage(body)
        }
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.shiftKey)) {
            event.preventDefault();
            setInputValue(inputValue + '\n');

            setTimeout(() => {
                inputElement.scrollTop = inputElement.scrollHeight;
            }, 1);

        } else if (event.key === 'Enter') {
            event.preventDefault();
            setInputValue('');
            sendMessage();
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const isSelfReply = () => {
        return selectedReplyMessage.authorId === requestUserInfo.id
    }

    useEffect(() => {
        if (!inputElement) return

        const scrollHeight = inputElement.scrollHeight

        if (!inputValue || scrollHeight < 55) {
            setInputHeight(2.2)
        } else if (scrollHeight >= 55 && scrollHeight < 80) {
            setInputHeight(3.2)
        } else if (scrollHeight >= 80) {
            setInputHeight(4.2)
        }
    }, [inputValue])

    return (
        <Wrapper flexbox column width='100%'>

            {
                selectedReplyMessage && (
                    <ReplyMessageWrapper flexbox isOpen={selectedReplyMessage}>

                        <Wrapper flexbox column width='100%' bgColor='background.01'
                            style={{ borderLeft: `4px solid ${isSelfReply() ? `${colors.getColor('primary')}BF` : '#53BDEBBF'}` }}
                            padding='0.5rem' corner='default' fontSize='small' gap='small' >

                            <Text opacity='0.5' color={isSelfReply() ? 'primary' : '#53BDEB'} medium>{selectedReplyMessage?.authorName}</Text>

                            <Wrapper flexbox maxHeight='7rem' style={{ overflowY: 'scroll' }}>
                                <Text opacity='0.5' color='white'>{selectedReplyMessage?.comment}</Text>
                            </Wrapper>

                        </Wrapper>

                        <Wrapper flexbox padding='horizontal' center onClick={() => cancelReplyMessage()}>
                            <Icon name='close' fill='white' size={25} />
                        </Wrapper>

                    </ReplyMessageWrapper>
                )
            }

            <Wrapper flexbox width='100%' border='top' align='center'>

                <Input
                    id='chat_comment'
                    type='textarea'
                    height={`${inputHeight}rem`}
                    placeholder='Adicionar Comentário'
                    onChange={handleInputChange}
                    value={inputValue}
                    width='100%'
                    onKeyDown={handleEnterKey}
                />

                <Wrapper flexbox height='100%' center width='fit-content' onClick={() => sendMessage()} border='left' padding='horizontal'>
                    <Icon name='send' fill='white' size={25} />
                </Wrapper>

            </Wrapper>

        </Wrapper>
    )
}

export default ChatInput