
function excelTextToJson(clipboardText, forceHeaders) {
    const [rawInputHeaders, ...rawInputRows] = clipboardText.split(/\r\n|\n/).map(line => line.split(/\t/));
    const headers = forceHeaders || rawInputHeaders;
    const rows = rawInputRows.reduce((agg, arr) => {
        if (headers.length !== arr.length) return agg;
        agg.push(arr.reduce((obj, item, index) => {
            obj[headers[index]] = item;
            return obj;
        }, {}));
        return agg;
    }, []);
    return {
        headers,
        rows,
    };
}

export {
    excelTextToJson
}