import React, { useState, useEffect } from "react";
import { Wrapper, Text, Icon } from "../../../../../components";
import { colors } from "../../../../../assets/theme";
import { simpleCurrency } from "../../../../../assets/services/formatters";
import { ExpandablePanel, WalletChart } from "../../Components";
import Loading from "../../../Loading";
import { removeElementsOpacity } from "../../../../../assets/services/apis";

const Wallet = ({ customer, viewInfo, isLoading }) => {

    const [shouldRenderChart, setShouldRenderChart] = useState(false)

    const allocationRules = viewInfo?.goplianceRules
    const customerWallet = viewInfo?.wallet

    useEffect(() => {
        setShouldRenderChart(customerWallet)
        customerWallet && removeElementsOpacity(100, 'opacity_card_wallet')
    }, [viewInfo])

    return (
        <Wrapper flexbox column>
            <Wrapper flexbox border='bottom'>

                <Wrapper flexbox column width='100%' border='right' padding='2rem' height='22rem' justify='center' gap={isLoading && '3rem'}>

                    <Text medium>Carteira de Ativos</Text>

                    {
                        !isLoading ? (
                            <WalletChart
                                shouldRenderChart={shouldRenderChart}
                                data={customerWallet}
                            />
                        ) : <Loading type='pie' height='12rem' width='12rem' />
                    }


                </Wrapper>

                {/* <Wrapper flexbox column width='50%' padding='2rem' gap='2rem'>

                    <Text medium>Gopliance</Text>

                    {
                        !isLoading ? (
                            allocationRules && (
                                <Wrapper flexbox column gap className='opacity_card_wallet'>
                                    {
                                        Object.keys(allocationRules).map((rule) => {
                                            return (
                                                allocationRules?.[rule]?.wallet?.length > 0 && (
                                                    allocationRules?.[rule]?.wallet.map((product, index) => {
                                                        return (
                                                            <Wrapper key={index} flexbox justify='space-between' fontSize='tiny' align='center'>

                                                                <Text>{product.display}</Text>

                                                                <Wrapper flexbox gap align='center'>

                                                                    <Wrapper flexbox align='center' gap='0.3rem'>
                                                                        <Text color={!product?.valid && colors.getColor('danger')}>{simpleCurrency(product?.net)} /</Text>
                                                                        <Text opacity='0.8'>{simpleCurrency(customer?.customerNet * product?.maxAllocation)}</Text>
                                                                    </Wrapper>

                                                                    {
                                                                        product?.valid ? (
                                                                            <Icon name='done' fill={colors.getColor('primary')} size={20} />
                                                                        ) : (<Icon name='warning' fill={colors.getColor('danger')} size={20} />)
                                                                    }

                                                                </Wrapper>

                                                            </Wrapper>
                                                        )
                                                    })
                                                )
                                            )
                                        })
                                    }
                                </Wrapper>
                            )
                        ) : [1, 2, 3, 4].map((item, index) => { return <Loading key={index} type='box' height='2rem' width='100%' /> })
                    }

                </Wrapper> */}

            </Wrapper>

            <Wrapper flexbox column gap padding>
                {
                    customerWallet && (
                        customerWallet.filter(item => item.net > 0).map((item, index) => {
                            return (
                                <ExpandablePanel
                                    key={index}
                                    className='opacity_card_wallet'
                                    data={item}
                                    customerNet={viewInfo?.customerNet}
                                />
                            )
                        })
                    )
                }
            </Wrapper>
        </Wrapper>
    )
}

export default Wallet