import colors from '../../assets/theme/colors'

import withStyles from '../../assets/services/styles'

const styles = `
  background-color: rgba(0,0,0, 0.4);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  will-change: opacity, z-index;
  z-index: -10;

  .content {
    background-color: ${colors.getColor('background.light')};
    border-radius: 4px;
    display: flex;
    box-shadow: 0 19px 60px rgba(0,0,0,.3),0 15px 20px rgba(0,0,0,.22);
    flex-direction: column;
    max-height: 90%;
    overflow-y: auto;

    transition-delay: .07s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .35s;
    transition-property: opacity,transform;
    transform: translateY(-200%);
    max-width: 90%;
  }
`

const options = {
  open: `
    opacity: 1;
    z-index: 999999;

    .content {
      transform: translateY(0);
    }
  `,
  height: ({ height }) => `
    .content {
      height: ${height};
    }
  `,
  minHeight: ({ minHeight }) => `
    .content {
      min-height: ${minHeight};
    }
  `,
  width: ({ width }) => `
    .content {
      width: ${width};
    }
  `,
}

export default withStyles(styles, options)
